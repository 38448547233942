.container_image{
  height:3vh;
  width:4vw;
  align-items: center;
  justify-content:center;
  margin-right:2vw
}

.container_image img{
  max-height:100%;
  max-width:100%;
  object-fit:contain;
}

.goBack{
  height:3vh;
  width:3vh;
  margin-right:1vw;
}