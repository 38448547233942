.content_offre {
  flex-direction: column;
  flex-shrink: 0;
}

.content h3 {
  display: none;
}
/* presentation offre */
.container_section {
  flex-direction: column;
  margin-top: 4vh;
  border-bottom: 1px solid var(--border);
}

.container_title {
  flex-direction: row;
  align-items: center;
}

.container_icon {
  height: 3vh;
  width: 3vh;
  margin-right: 2vw;
}

.container_icon img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.label_title {
  font-size: var(--size_2);
  font-weight: 500;
}

.content {
  margin-top: 2vh;
  font-size: var(--size_3) !important;
  flex-direction: column;
}

.logIn {
  color: var(--color);
  align-self: center;
  font-size: var(--size_3);
}

.content_cv {
  margin: 0 10vw;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
}

.label_apply {
  text-align: center;
  font-size: var(--size_2);
}

.content_letter {
  margin: 0 5vw;
  align-items: center;
  justify-content: center;
  flex: 1;
  justify-content: space-evenly;
}

.footer_apply {
  justify-content: space-between;
  flex: 1;
  align-items: center;
}

.button_back {
  color: var(--font);
  font-size: var(--size_3);
  cursor: pointer;
}

.content :global .inner {
  flex-direction: column;
}

.content div {
  flex-direction: column;
}
