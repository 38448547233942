/* intro */

.container_intro{
  padding:5vh;
  align-items: center;
  justify-content: space-evenly;
}

.intro_title{
  font-size:4vh;
  font-weight:500;
  text-align: center;
}

.intro_image{
  height:30vh;
  max-width:80%
}

.loading_image{
  height:10vh;
  max-width:50%
}

.intro_image img, .loading_image img{
  max-height:100%;
  max-width:100%;
  object-fit:contain;
}

.intro_text{
  text-align: center;
  font-size:var(--size_2);
  color:var(--font);
}



/* subscribe */

.container_subscribe{
  flex-direction: column;
  align-self: stretch;
}

.container_other{
  color:var(--font);
  font-size:var(--size_3);
  margin:3vh 0;
  align-items: center;
  justify-content: center;
}


.banner_button{
  padding:2vh 2vw;
  border-top:1px solid var(--border);
  justify-content: flex-end;
}

.container_change{
  padding:4vh 2vw;
  flex-direction: row;
  align-items: center;
}

.changing_text{
  flex-direction: column;
}

.changing_text_item{
  display:inline;
  font-size:var(--size_2);
  margin:2vh;
}

.changing_text_item span{
  color:var(--red);
}