.page{
  padding:2vh 1vw;
}

.label{
  font-size:var(--size_2);
  flex:1;
}

.views{
  font-size:var(--size_3);
  color:var(--font);
}