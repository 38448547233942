.container_addlist{
  flex-direction:column;
}

.container_item_addlist{
  flex-direction:column;
}

.container_fields_addlist{
  flex-direction:row;
  flex-wrap:wrap
}

.add_button{
  border:1px solid var(--color);
  align-self:flex-start;
  border-radius:1vh;
  padding:1vh;
  margin:1vh;
  align-items: center;
}

.add_button img{
  height:2vh;
  width:2vh;
}

.add_button span{
  font-size:var(--size_3);
  color:var(--color);
  margin:0 1vh;
}

.delete{
  color:var(--red);
  font-size:var(--size_3);
  align-self:flex-end;
  margin-right:1vh;
}

@media only screen and (max-width: 600px) {

}