#container_recruteurs {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-self: stretch;
  flex: 1 1;
  position: relative;

  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

#body_recruteurs {
  flex-direction: column;
  padding: 10vh 15vw;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
}

.background_recruteurs {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.overlay_recruteurs {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--sub_color);
  opacity: 0.3;
}

.content_header_recruteurs {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 5vh 10vw;
  color: white;
  font-size: 4vh;
  font-weight: 600;
  text-align: center;
}

.content_header_recruteurs img {
  height: 10vh;
}

#body_recruteurs .title_section {
  margin: 10vh 0;
  align-self: center;
}

.header_lexique {
  height: 40vh;
  align-self: stretch;
  border-radius: 1vh;
  overflow: hidden;
  position: relative;
}

#content_lexique {
  align-self: stretch;
  font-family: "Futura";
  margin-top: 6vh;
  flex-direction: column;
  flex-shrink: 0;
}

.category_lexique {
  flex-direction: column;
  align-self: stretch;
  flex: 1;
  margin-bottom: 2vh;
}

.letter_lexique {
  font-size: 5vh;
  font-weight: bold;
  border-bottom: 1px solid var(--grey_border);
  margin-bottom: 3vh;
}

.item_lexique {
  flex-direction: column;
  margin-bottom: 4vh;
}

.word_lexique {
  color: var(--purple);
  font-size: 2.5vh;
  font-weight: 500;
  margin-bottom: 1.5vh;
}

@media only screen and (max-width: 600px) {
  #body_recruteurs {
    width: 100%;
    box-sizing: border-box;
    padding: 2vh 5vw;
  }
  #body_recruteurs .title_section {
    text-align: center;
  }

  .content_header_recruteurs {
    font-size: 2.7vh;
  }
}
