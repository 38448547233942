.header_cv{
	height:6vh;
	background-color: black;
	align-self:stretch;
	justify-content: space-between;
	z-index:12;
	align-items: center;
	padding:1vh 2vw;
}

.intro_cv{
	align-items: center;
}

.label_intro_cv{
	color:white;
	font-size:1.8vh;
	margin:0 1vw;
}

.close_cv{
	background-color:var(--color);
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	height:3vh;
	width:3vh;
	padding:0.5vh;
	box-sizing:border-box;
}

.item_option_cv{
	margin:0 0.5vw;
}

.item_option_cv img{
	height:3vh;
	width:3vh;
}

.container_cv{
	flex:1;
	align-self:stretch;
  width: 100vw;
  align-items: center;
	justify-content: center;
	background-color: 'red';
}

.navigate_cv{
	position:absolute;
	z-index:11;
	background-color:var(--color);
	border-radius: 50%;
	padding:1vh;
	top:50%;
	transform:translateY(-100%);
	align-items: center;
	justify-content: center;
}

.navigate_cv img{
	height:3vh;
	width:3vh;
}

.navigate_cv_left{
	left:2vw;
}

.navigate_cv_right{
	right:2vw;
}




.container_zoom{
	position:absolute;
	background-color: var(--color);
	padding-left:1vh;
	padding-right:1vh;
	bottom:2vh;
	left:50%;
	transform:translateX(-50%);
	border-radius:3vh;
	box-shadow:var(--shadow);

}

.container_item_zoom{
	padding:1vh;
}

.container_item_zoom img{
	height:2vh;
	width:2vh;
}



.cv_buddy_score{
	z-index:2;
	background-color: var(--color);
	border-radius:50%;
	position:absolute;
	top:10vh;
	right:20vw;
	height:15vh;
	width:15vh;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-shadow:var(--shadow);
}

.cv_buddy_score img{
	height:3vh;
	width:3vh;
	margin-bottom: 1vh
}

.label_cv_buddy_score{
	color:white;
	font-size:1.5vh;
	margin-bottom: 1vh;
	opacity:0.8;
}

.score_cv_buddy_score{
	font-size:4vh;
	color:white;
	font-weight:600;
}





.container_pages_pdf{
	height:90vh;
	background-color: 'red';
}

.container_pages_pdf .view{
	padding-top:5vh;
}

.react-pdf__Document{
	flex:1;
	align-items: center;
	justify-content: center;
}

.react-pdf__Page{
	align-items: center;
	justify-content: center;
	margin-bottom:1vh;
	flex-shrink:0;
}

.react-pdf__Page__annotations{
	display:none;
}