.container_list_matching {
  flex: 1 1;
  border-radius: 1vh;
  background-color: var(--background_list);
  align-self: stretch;
  flex-direction: column;
  overflow: hidden;
  padding:0 1vw;
}

.container_list_matching:nth-child(1){
  margin: 3vh 1vw 0 1vh;
}

.container_list_matching:nth-child(2){
  margin: 3vh 1vw 0 1vw;
}

.container_list_matching:nth-child(3){
  margin: 3vh 1vh 0 1vw;
}

.list_header_matching{
  align-items:center;
  padding:0 1vw;
  flex-direction:column;
}

.container_options{
  margin-left:1vw;
  align-items:center;
}

.list_trash{
  background-color:#fef2f2;
}

.title{
  font-size:var(--size_3);
  align-items: flex-start;
  padding:2vh 0;
  align-self:stretch;
}

.searchbar{
  align-items: center;
  align-self:stretch;
}

.item_option{
  margin-left:0.5vw;
}

.index{
  color:white;
  background-color: var(--color);
  border-radius:50%;
  height:2.5vh;
  width:2.5vh;
  font-size:var(--size_4);
  align-items: center;
  justify-content: center;
  font-weight:500;
  margin-right:1vw;
  flex-shrink:0;
}

.header{
  align-self:stretch;
  align-items:center;
}
