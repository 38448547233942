.outer_drawer{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  justify-content: flex-end;
}

.background{
  background-color: black;
  left:0;
  right:0;
  bottom:0;
  top:0;
  opacity:0.6;
  position:absolute;
  z-index:2
}

.container_drawer{
  background-color: white;
  z-index:3;
  align-self:stretch;
  width:50%;
  flex-direction: column;
}

.tab_drawer{
  align-self: stretch;
}

.item_tab_drawer{
  border-bottom:1px solid var(--border);
  padding:1vh;
  flex:1;
  align-items:center;
  justify-content: center;
  font-size:var(--size_3);
}

.item_tab_drawer.active{
  border-color:var(--color);
  color:var(--color);
}