.outer_form{
	flex-direction:column;
	flex:1;
	align-self:stretch;

	overflow:hidden;
}

.container_form{
	flex-direction:row;
	flex-wrap:wrap;
	margin-bottom:2vh;
	max-height:none !important;
}

.error_form{
	color:var(--red);
	margin-top:1vh;
	margin-bottom:1vh;
	font-size:1.8vh;
}

.form_view{
	padding:0 4vh;
}

.title_section{
	font-size:var(--size_2);
	font-weight:500;
	margin:2vh;
	margin-top:4vh;
	width:100%;
}

.section{
	flex-direction:row;
	flex-wrap:wrap;
	align-self:stretch;
	/* added for form cab in admin */
	width:100%;
}