.container_edit_buddy_scan{
  flex-direction:row;
  flex:1;
  align-self:stretch;
}

.container_menu{
  flex-direction:column;
  width:20vw;
}

.container_question{
  flex-direction: column;
  flex:1;
}

/*menu question */ 
.container_menu{
  border-right:1px solid var(--border);
  padding-top:1.5vh;
}

.menu_question{
  align-items:flex-start;
  padding:1.5vh 1vw;
}

.index_menu_question{
  background-color: var(--color);
  height:2vh;
  width:2vh;
  line-height:2vh;
  border-radius: 50%;
  font-size:1.4vh;
  color:white;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right:0.5vw;
}

.label_menu_question{
  font-size:var(--size_3);
}

.active_menu_question .index_menu_question{
  background-color: var(--dark_color);
}

.active_menu_question .label_menu_question{
  color:var(--dark_color);
}

.banner_button{
  align-self:stretch;
  align-items:center;
  justify-content: flex-end;
  padding:2vh 2vw;
  border-top:1px solid var(--border)
}

.navigate{
  background: var(--light_grey);
  padding:0.5vh;
  margin-right:1vw;
}

.disabled{
  pointer-events:none;
  opacity:0.5;
}

@media only screen and (max-width: 600px) {
  .container_administratif{
    margin:2vh 5vw;
  }

  .container_menu{
    display:none;
  }
}