.container_photo {
  height: 10vh;
  width: 10vh;
  flex: none;
  padding: 0;
}

.photo,
.inner_photo {
  overflow: hidden;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.photo img {
  object-fit: contain;
  max-height: 8vh;
  max-width: 8vh;
}

.banner {
  width: 100%;
  height: 100%;
}

.banner img,
.round img {
  object-fit: cover;
  min-height: 100%;
  min-width: 100%;
  max-height: none;
  max-width: none;
}

.container_add_picture {
  position: relative;
}

.input_file {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}

input[type="file"],
input[type="file"]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.photo.add_picture {
  height: 10vh;
  width: 10vh;
}

.photo.add_picture img {
  height: 5vh;
  width: 5vh;
  min-height: 0;
  min-width: 0;
  max-height: none;
  max-width: none;
}

.container_min_photo {
  border: none;
}

.min_photo {
  align-items: center;
}

.min_photo img {
  height: 2vh;
  width: 2vh;
  min-height: 0 !important;
  min-width: 0 !important;
  margin-right: 1vh;
}

.min_photo span {
  color: var(--font);
  font-size: var(--size_3);
}

.round {
  height: 12vh;
  width: 12vh;
  border-radius: 50%;
}

.round .photo {
  border-radius: 50%;
  overflow: hidden;
}

.item_missing {
  border-color: var(--red);
}

.error {
  color: var(--red);
  font-size: 12px;
  padding-top: 10px;
}
