.inner_list{
	flex-direction: column;
	overflow-y:scroll;
	align-self:stretch;
	display:flex;
	flex:1;
	align-items:stretch;
	
	overflow-y: scroll;
	overflow-x: hidden; 
	-webkit-overflow-scrolling: touch;
}

.content_list{
	flex-direction:column;
	align-self:stretch;
	flex:1;
}

.list{
	flex:1;
	align-self:stretch;
	overflow:hidden;
	flex-direction:column;
}

.view{
	flex-direction: column;
}



.noScroll{
	flex:none;
	overflow:visible;
	width:100%;
}