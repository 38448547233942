.banner_insights {
  background-color: var(--light_grey);
  align-items: center;
  padding: 0 2vw;
  width: 100%;
  /*  height: 60px; */
}

.container_insights {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  padding: 1vh 0;
}

.insights {
  width: 25%;
  padding: 0 1vw;
  margin: 1vh 0;
  box-sizing: border-box;
  font-size: var(--size_3);
  align-items: center;
  align-self: stretch;
  justify-content: center;
}

.insights:not(:last-child) {
  border-right: 1px solid var(--border);
}

.insights:nth-child(5n) {
  border: none;
}

.logo_insights {
  height: 3vh;
  width: 3vh;
  margin-right: 1vw;
}

.cb_insights {
  background-color: var(--color);
  border-radius: 2vh;
  font-size: var(--size_3);
  font-weight: 500;
  color: white;
  padding: 0.5vh 1vw;
  margin: 0 1vw;
  align-items: center;
  line-height: 2vh;
  margin-left: 5vh;
}

.logo_cb_insights {
  height: 2vh;
  width: 2vh;
  margin-right: 0.5vw;
}

.annex {
  width: 8vw;
  align-items: center;
  justify-content: center;
}

.annex img,
.annexNext img {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .banner_insights {
    overflow-x: scroll;
    padding: 1vh 2vw;
  }

  .container_insights {
    flex: none;
  }

  .insights {
    width: auto;
    padding: 0 4vw;
  }

  .logo_insights {
    margin-right: 2vw;
  }
}
