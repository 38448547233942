.card_article_coaching{
  width:30%;
  height:35vh;
  flex-direction: column;
  padding:2vh;
  box-sizing:border-box;
  margin-top:2vh;
  margin-bottom:2vh;
  overflow:hidden;
}

.image_article_coaching{
  height:35%;
  align-items: center;
  justify-content:center;
}

.image_article_coaching img{
  max-height:100%;
  max-width:100%;
  object-fit: contain;
}

.title_article_coaching{
  font-size:var(--size_2);
  font-weight:500;
  margin:2vh 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.abstract_article_coaching{
  font-size:var(--size_3);
  color:var(--font);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}




.container_header_coaching{
  width:100%;
}

@media only screen and (max-width: 600px) {
  .card_article_coaching{
    width:100%;
    height:auto;
  }
  .image_article_coaching{
    height:30vh;
  }
}
