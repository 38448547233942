.container_delete{
  background-color: var(--color);
  border-radius:50%;
  padding:0.5vh;
  margin:0 1vh;
  align-self:center;
}

.container_delete_absolute{
  position:absolute;
  top:-1vh;
  right:-1vh;
  margin:0;
}

.container_delete img{
  height:1.6vh;
  width:1.6vh;
}