.container_recruit{
	display:flex;
	flex-direction:column;
}

.container_round_recruit{
	display:flex;
	flex-direction:column;
	margin-left:3vh;
}

.container_round{
	display:flex;
	flex-direction:column;
	border-left:2px solid var(--color);
	padding-left:3vh;
	margin:2vh 0;
}

.container_details_round{
	display:flex;
	flex-direction:column;
}

.container_details_round{
	display:flex;
	flex-direction:column;
	margin-left:3vh;
	margin-top:5vh;
}

.title_details_round{
	font-size:2vh;
	color:var(--color);
	font-weight:600;
}

.title_round{
	font-size:2.2vh;
	color:var(--color);
	font-weight:bold;
}




.container{
	flex-direction: column;
	align-items:center;
	width:100%;
}

.switch{
	align-self:center;
	border-radius:1vh;
	overflow:hidden;
	margin-top:4vh;
}

.item_switch{
	font-size:var(--size_2);
	padding:1.5vh 1vw;
	background-color: var(--light_grey);
	width:5vw;
	align-items:center;
	justify-content: center;
	cursor:pointer;
}

.item_switch.active{
	background-color: var(--color);
	color:white;
}