.container_field{
  flex-direction: column;
}

.containerOptions{
  flex-direction: column;
}

.option{
  flex-direction: column;
  margin:1vh 0;
}

.checkbox{
  height:2vh;
  width:2vh;
  border-radius:50%;
  border:1px solid var(--border);
  margin-right:1vw;
  flex-shrink:0;
}

.selectedOption .checkbox{
  border-color:var(--color);
  background-color: var(--color);
}

.label{
  font-size:var(--size_2);
  display:inline;
}

.label a{
  color:var(--color);
  text-decoration:underline;
  display:inline;
}

.item_option{
  flex-direction: row;
  align-items: center;
  margin:1vh 0;
}

@media only screen and (max-width: 600px) {
  .checkbox{
    margin-right:2vw;
  }
  .item_option{
    margin-bottom:0 !important;
  }
}
