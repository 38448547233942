.container {
  flex-direction: column;
}

.text {
  font-size: var(--size_2);
  flex-direction: column;
  line-height: 3vh;
}

/* ------------------------ PIE ---------------------- */

.container_pie {
  min-height: 40vh;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 5vh;
  align-items: center;
}

.pie {
  width: 22vw;
  height: 40vh;
  position: absolute;
  align-items: center;
  justify-content: center;
}

.recharts-responsive-container {
  z-index: 2;
}

.container_schools {
  left: 0;
  right: 0;
  flex-direction: row;
  align-items: center;
  align-self: center;
  flex-wrap: wrap;
  width: 100%;
}

.logo_graph_school {
  position: absolute;
  height: 10vh;
  width: 7vw;
  align-items: center;
  justify-content: center;
}

.container_list_school {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  justify-content: center;
}

.container_list_school:first-child {
  margin-right: 10vw;
}

.container_list_school:last-child {
  margin-left: 10vw;
}

@media only screen and (max-width: 600px) {
  .container_pie {
    margin: 0;
    height: auto;
    padding: 0;
  }

  .pie,
  .logo_graph_school {
    display: none;
  }

  .container_schools {
    position: relative;
    height: auto;
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .container_list_school {
    flex: none;
    margin: 0 !important;
  }
}
