.container_cab {
  margin: var(--margins);
  flex-direction: column;
  background-color: white;
  margin-left: 10vw;
  margin-right: 10vw;
  flex-shrink: 0;
}

/* section */

.section {
  padding: 5vh 2vw;
  border-bottom: 1px solid var(--border);
  flex-direction: column;
}

.title {
  flex-direction: row;
  margin-bottom: 2vh;
  font-family: "Futura";
}

.square_title {
  height: 1vh;
  width: 1vh;
  background-color: var(--color);
  align-self: flex-end;
  margin: 1vh;
}

.label_title {
  font-size: var(--size_1);
}

@media only screen and (max-width: 600px) {
  .container_cab {
    margin: 0;
  }
  .section {
    padding: 4vh 5vw;
  }
}
