.content_footer{
  border-top:1px solid var(--border);
  height:7vh;
  align-items:center;
  padding:0 2vw;
  justify-content: space-between;
}

.candidate{
  flex-direction: column;
}

.name{
  font-size:var(--size_2);
}

.date{
  font-size:var(--size_3);
  color:var(--font);
}