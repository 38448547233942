.content{
  flex-direction: column;
  flex:1;
  align-items: center;
  justify-content:space-evenly;
}


.separator{
  font-size:var(--size_3);
  color:var(--font);
}


.form_login{
  flex-direction: column;
  align-self: stretch;
  margin: 0 5vw;
}

.forgot{
  color:var(--color);
  font-size:var(--size_3);
  align-self: flex-end;
}

.error{
  color:var(--red);
  font-size:var(--size_3);
  margin-right:1vw;
}






.banner_recruit{
  background: var(--light_grey);
  padding:3vh 5vw;
  text-align:center;
  white-space:pre;
  font-size:var(--size_3);
  align-items: center;
  justify-content:center;
}

.link_recruteur{
  text-decoration: underline;
}


.text_login{
  font-size:var(--size_3);
  margin:0 5vw;
  text-align:center;
}

.loginApply{
  flex-direction: column;
  min-height:80vh;
}

.loginApply .buttons{
  align-self:center;
  margin-bottom:4vh;
}