.container_icon_filter{
	background: var(--purple);
	padding:1vh;
	width:5vh;
	align-items:center;
	justify-content: center;
	position:relative;
}

.container_icon_filter img{
	height: 4vh;
    width: 4vh;
}

.container_icon_filter .number_selected{
	background-color:white;
	border-radius:50%;
	color:var(--purple);
	height:3vh;
	width:3vh;
	box-shadow:var(--shadow_list);
	position:absolute;
	top:0.5vh;
	right:0.5vh;
	margin:0;
}

#container_filter{
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	z-index:10;
	background-color: white;
	display:none;
	flex-direction: column;
}

.show_filter #container_filter{
	display:flex;
}

#header_filter{
	background-color: var(--grey_border);
	align-self:stretch;
	margin-top:6vh;
	align-items:center;

}

#close_filter{
	padding:3vh;
}

#close_filter img{
	height:2vh;
	width:2vh;
}

#title_filter{
	margin-left:2vh;
	font-weight:500;
	font-size:2.5vh;
}


#container_filters{
	flex-direction:column;
	flex:1;
	overflow-y: scroll;
}

.filter{
	flex-direction: column;
	flex-shrink:0;
}

.container_title_filter{
	color:var(--purple);
	padding:2vh;
	font-size:2.5vh;
	border-bottom:1px solid var(--purple);
	align-items: center;
}

.container_title_filter img{
	height:2vh;
	width:2vh;
	-webkit-transition: transform 1s ease;
    transition: transform 1s ease;
}

.show_list_filter .container_title_filter img{
	transform:rotate(180deg);
}

.container_title_filter .title_filter{
	flex:1;
}

#container_filters .container_cab_list{
	border-bottom:1px solid var(--grey_border);
	padding:2vh;
}

#container_filters .container_cab_list:last-child{
	border-bottom:1px solid var(--purple);

}


#container_filters .name_cab_list{
	padding-left:2vh;
}

.list_filter{
	display:none;
	flex-direction:column;
}

.show_list_filter .list_filter{
	display:flex;
}

@media only screen and (max-width: 600px) {

	#searchbar{
		padding:0;
		overflow:hidden;
	}

	#container_searchbar{
		position:unset;
	}


}
