.container_offres {
  margin: var(--margins);
  flex-direction: column;
  flex-shrink: 0;
}

/* switch */
.container_item_switch {
  padding: 5vh 3vw;
  align-items: center;
  flex: 1;
}

.container_item_switch:first-child {
  border-right: 1px solid var(--border);
}

.container_image_switch {
  height: 10h;
  width: 5vw;
  margin: 0 2vw;
  align-items: center;
  justify-content: center;
}

.container_description_switch {
  flex-direction: column;
}

.title_switch {
  font-size: var(--size_2);
  font-weight: 500;
  margin-bottom: 1vh;
  font-family: "Futura";
}

.description_switch {
  font-size: var(--size_3);
}

.active_switch {
  color: var(--color);
}

/* info */
.container_infos {
  background: var(--grey_background);
  margin: 1vh;
  flex-direction: column;
  padding: 5vh 2vw;
}

.banner_button {
  align-self: flex-end;
  margin-top: 2vh;
}

@media only screen and (max-width: 600px) {
  .container_offres {
    margin: 0;
  }
  .container_infos {
    padding: 5vh 6vw;
  }
  .container_item_switch {
    padding: 2vh 5vw;
  }
  .title_switch {
    font-size: var(--size_3);
    margin: 0;
  }
  .container_image_switch {
    margin: 0 3vw;
  }
  .tab_offers {
    margin: 0;
  }
}
