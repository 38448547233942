#container_social_networks{
	flex-direction:row;
}

.social_network{
	cursor:pointer;
	margin-right:1.5vw;
	height:4vh;
	overflow:hidden;
}

.social_network:hover{
	opacity:0.7;
}

.social_network img{
	height:4vh;
}

.social_network.sn_2 img{
	height:50%;
}

.social_network a{
	height:100%;
	display:flex;
	flex-direction:column;
}





.container_grade{
	flex:1;
	height:1.5vh;
}

.grade{
	color:#26AA2B;
	flex:1;
	align-items:center;
	justify-content: center;
	font-weight:bold;
	font-size:1.5vh;
}

.container_stars{
	align-items: center;
	justify-content: center;
}


.social_network.sn_2 .container_stars img{
	height:100%;
	z-index:2;
}

.container_star{
	height:1.25vh;
	width:1.25vh;
	align-items:center;
	justify-content:center;
}

.sn_6 div{
	display:block;
	height:100%;
}

@media only screen and (max-width: 600px) {
	.social_network{
		margin-right:5vw;
	}

}