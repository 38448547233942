.header{
	background-color: var(--color);
	padding:2vh;
	box-shadow: var(--shadow);
	align-items: center;
	position:relative;
}

.container_nav{
	flex:1;
}

.item_header{
	color:white;
	margin-left:2vh;
	margin-right:2vh;
	text-decoration:none;
	font-weight:600;
	font-size:var(--size_2);
	cursor:pointer;
	opacity:0.6;
}
 .active{
	 opacity:1;
 }




 .container_menu{
	flex-direction:column;
	margin-right:2vw;
 }

 .container_name{
	color:white;
	font-size:var(--size_3);
	align-items: center;
	font-weight:500;
	align-self: flex-end;
 }

 .container_menu .container_hidden_menu{
	display:flex;
 }

 .container_hidden_menu{
	 display:none;
	 position:absolute;
	 top:90%;
	 background-color: white;
	 box-shadow:var(--shadow);
	 font-size:var(--size_3);
	 color:var(--font);
	 padding:1.5vh 1vw;
	 border-radius:1vh;
	 right:3vw;
 }


