.linkedin{
  position:relative;
  flex:1;
}

.icon{
  height:3vh;
  width:3vh;
  align-items:center;
  justify-content: center;
  cursor:pointer;
}

.icon:hover .details{
  display:flex;
}

.details{
  position:absolute;
  top:4vh;
  left:-5vw;
  background:white;
  box-shadow: var(--shadow);
  z-index:3;
  align-items: center;
  width:30vw;
  padding:2vh;
  display:none;
}

.illustration{
  width:20vw;
  height:30vh;
}

.label{
  font-size:var(--size_3);
  color:var(--font);
  margin-left:2vw;
}
