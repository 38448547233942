.container_list_articles {
  margin: var(--margins);
  flex-direction: column;
  flex-shrink: 0;
}

@media only screen and (max-width: 600px) {
  .container_list_articles {
    margin: 0;
  }
}
