.content_banner_progress{
  align-self:stretch;
  background-color: var(--background);
  padding:2vh 3vw;
}

.container_item_progress{
  flex:1;
  align-items: center;
  justify-content:center;
  flex-direction:column;
  position:relative;
  align-self:stretch;
}

.container_number_progress{
  flex:1;
}

.number_progress{
  background-color: var(--grey);
  color:white;
  align-items: center;
  justify-content:center;
  font-size:var(--size_2);
  height:4vh;
  width:4vh;
  border-radius:50%;
  line-height:4vh;
  z-index:2;
}

.active_progress .number_progress{
  background-color: var(--color);
}

.container_label_progress{
  flex:1;
  font-size:var(--size_3);
  text-align:center;
  margin:0.5vh;
}

.container_label_progress p{
  margin:0;
}

.after_bar, .before_bar{
  position:absolute;
  width:50%;
  top:2vh;
  border-bottom:1px solid var(--border);
}

.after_bar{
  transform:translateX(55%)
}

.before_bar{
  transform:translateX(-55%)
}

.current_active_progress .number_progress{
  background-color: var(--dark_color);
}

.active_progress .before_bar{
  border-color:var(--color);
}

.active_progress:not(.last_active_progress) .after_bar{
  border-color:var(--color);
}

/* buttons */

.container_buttons{
  justify-content: space-between;
  flex:1;
  align-items: center;
}

.button_back{
  color:var(--font);
  font-size:var(--size_3)
}

.button_back.disabled{
  pointer-events: none;
  opacity:0.5;
}

/* intro */

.content_progress{
  flex-direction: column;
  margin:2vh 0;
}

.container_section{
  flex-direction: column;
  margin:2vh 0;
}

.container_illustration{
  align-self:center;
  height:20vh;
  width:20vh;
  margin-bottom:2vh;
}

.container_illustration img{
  max-height:100%;
  max-width:100%;
  object-fit: contain;
}

.content_intro{
  flex-direction: column;
  text-align:center;
}

.content_intro div{
  margin:2vh 5vw;
  font-size:var(--size_2);
}

.container_title{
  flex-direction:row;
  align-items: center;
  font-weight:600;
}

.container_icon{
  height:3vh;
  width:3vh;
  margin-right:2vw;
}

.container_icon img{
  max-height:100%;
  max-width:100%;
  object-fit: contain;
}

.label_title{
  font-size:var(--size_2);
}

.content{
  margin:2vh 2vw;
  flex-direction:column;
  font-size:var(--size_2);
}

.cv_seen{
  flex:1;
  font-size:var(--size_3);
  color:var(--font);
  justify-content: flex-start;
  align-items:center;
}

.containerProblem{
  position:absolute;
  top:2vh;
  left:1vw;
  z-index:3;
}

.iconProblem{
  height:2.5vh;
  width:2.5vw;
  cursor:pointer;
  align-items:center;
  justify-content: center;
}

.menuProblem{
  position:absolute;
  background-color: white;
  box-shadow:var(--shadow);
  color:var(--red);
  font-size:var(--size_3);
  padding:2vh 1vw;
  cursor:pointer;
  top:4vh;
  width:10vw;
  display:none;
}

.containerProblem.showProblem .menuProblem{
  display:flex;
}

@media only screen and (max-width: 600px) {
	.containerProblem{
		left:5vw;
	}

	.menuProblem{
		width:auto;
    white-space: nowrap;
    padding:2vh 4vw;
	}
}
