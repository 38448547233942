.container_question{
  padding:2vh 2vw;
  flex-direction: column;
}

.label_question{
  text-decoration:underline;
  font-weight:500;
  margin-bottom:1vh;
  font-size:var(--size_2)
}

.container_answers{
  flex-direction: column;
}

.container_answer{
  font-size:var(--size_3);
  margin-top:0.5vh;
}