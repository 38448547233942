.senior_offers{
  flex-direction: row;
  margin:1vh 2vw;
  flex-wrap:wrap;
  align-items:center;
  justify-content: center;
}

.button{
  font-size:var(--size_3);
  color:var(--font);
  border:1px solid var(--font);
  padding:1vh 1vw;
  margin:1vh 0.5vw;
  border-radius:4vh;
}

.button_cab{
  background-color: var(--color);
  border-color: var(--color);
  color:white;
}

.outdated{
  background-color: var(--orange);
  border-color:var(--orange);
}