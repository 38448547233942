.min_cab{
	display:flex;
	flex-direction:column;
	border-radius:1vh;
	overflow:hidden;
	background-color: white;
	box-shadow: var(--shadow);
	margin:2vh 0;
	box-sizing:border-box;
}

.illustration_min_cab img, .loading_img_cab{
	height:12vw;
	width:100%;
	object-fit: cover;
	background-color: var(--grey);
}

.details_min_cab{
	display:flex;
	flex-direction:column;
	padding:3vh;
	padding-top:0;
	justify-content: space-evenly;
	flex:1;
	align-items: center;
}

.title_min_cab{
	font-weight:600;
	font-size:2.4vh;
	margin-bottom:1vh;
}

.loading_title_cab{
	width:60%;
	background-color: var(--grey_2);
	height:2vh;
	border-radius:1vh;
}

.logo_min_cab{
	background-color: white;
	height:7vw;
	width:7vw;
	border-radius:2vh;
	box-shadow: var(--shadow);
	transform:translateY(-50%);
	margin-bottom:-2vw;
	display:flex;
	align-items: center;
	justify-content: center;
}

.logo_min_cab img{
	max-height:80%;
	max-width:80%;
	object-fit: contain;
}

.name_min_cab{
	font-weight:600;
	font-size:var(--size_1);
	margin-bottom:2vh;
	font-family:"Futura";
	letter-spacing: 0.4px;
}

.loading_name_cab{
	width:60%;
	background-color: var(--grey_2);
	height:2vh;
	border-radius:1vh;
}

.container_info_min_cab{
	display: flex;
	flex-direction: row;
	align-items: center;
	color:var(--font);
	font-size:var(--size_3);
	margin:1vh 0;
	font-weight:500;
	align-self:stretch
}

.container_info_min_cab img{
	height:2vh;
	width:2vh;
	margin-right:1vw;
}

.loading_info_min_cab{
	width:50%;
	background-color: var(--grey_2);
	height:2vh;
	border-radius:1vh;
	opacity:0.5;
}

@media only screen and (max-width: 600px) {
	.min_cab{
		width:85%;
	}

	.illustration_min_cab img, .loading_img_cab{
		height:20vh;
		width:100%;
		object-fit: cover;
		background-color: var(--grey);
	}

	.logo_min_cab{
		height:20vw;
		width:20vw;
	}

	.container_info_min_cab img{
		height: 4vw;
	    width: 4vw;
	    margin-right: 4vw;
	}

	.min_cab .container_info_min_cab div{
		font-size:2.2vh;
		flex: 1;
	}
}