.inner_list{
	flex-direction: column;
	overflow-y:scroll;
	align-self:stretch;
	display:flex;
	flex:1;
	align-items:stretch;
	
	overflow-y: scroll;
	overflow-x: hidden; 
	-webkit-overflow-scrolling: touch;
}

.view{
	flex-direction: column;
}