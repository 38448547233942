
/* questions */

.container_question{
  flex-direction: column;
  padding:6vh;
}

.title{
  font-size:4vh;
  font-weight:500;
  margin-bottom:4vh;
}

.subtitle{
  font-size:var(--size_3);
  color:var(--font);
  margin-bottom:4vh;
}

/* list answers */

.container_list_answers{
  flex-direction:column;
}

.container_answer{
  margin:1vh 0;
  border:1px solid black;
  padding:1.5vh;
  border-radius:0.5vh;
  align-items:center;
}

.label_answer{
  font-size:var(--size_2);
}

.index_answer{
  background-color:var(--color);
  padding:1vh;
  border-radius:0.5vh;
  height:2vh;
  width:2vh;
  line-height:2vh;
  align-items: center;
  justify-content: center;
  font-size:var(--size_2);
  color:white;
  font-weight:500;
  margin-right:1.5vw;
}

.answer_selected{
  background-color: var(--light_color);
  border-color:var(--color);
}


/* answer list */
.container_answers_list{
  flex-direction: row;
  flex-wrap:wrap;
}

.container_answer_list{
  background-color: var(--grey);
  padding:1vh 1vw;
  border-radius:3vh;
  font-size:var(--size_3);
  margin:0 1vw 1vh 0;
}

.container_answer_list.answer_list_selected{
  background-color: var(--color);
  color:white;
}


@media only screen and (max-width: 600px) {
  .index_answer{
    margin-right:5vw;
  }

  .container_answer_list{
    padding:2vh 3vw;
    margin:0 1.5vw 1.5vh 0;
  }
}