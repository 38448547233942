.container_cv{
  flex-direction:column;
  padding:4vh 2vw;
}

.item_cv{
  padding-bottom:8vh;
  flex-direction:column;
}

.title_cv{
  font-size:var(--size_2);
  font-weight:500;
  margin-bottom:2vh;
}