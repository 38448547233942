.content{
  flex-direction: column;
  flex:1;
  align-items: center;
  justify-content:space-evenly;
  margin:4vh 0
}

.label_forget{
  margin: 4vh 5vw;
  font-size:var(--size_3);
}

.form_forget{
  margin: 0 5vw;
  flex-direction: column;
  align-self: stretch;
}