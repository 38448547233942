.item_content {
  padding: 1vh;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.image,
.video {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.5vh;
}

.image {
  cursor: pointer;
}

.container_image {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex: 1;
}

@media only screen and (max-width: 600px) {
  .item_content {
    width: 100% !important;
  }
}
