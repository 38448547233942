.header{
	display:flex;
	position:absolute;
	top:0;
	left:0;
	right:0;
	z-index:10;
	height:7vh;
}

.background_header{
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background: white;
	opacity:0.9;
}

.container_header{
	z-index:2;
	position:relative;
	align-items:center;
	margin:0 2vw;
	align-self:stretch;
	flex:1;
	justify-content: space-between;
}

.logo_header{
	height:4vh;
	width:4vh;
}

/* nav */
.nav_header{
	align-items:center;
}

.header_block{
	font-size:var(--size_2);
	font-weight:500;
	margin-left:2vw;
}

.selected{
	color:var(--color);
}

.recruteur{
	font-size:var(--size_2);
	text-decoration:underline;
	margin-right:2vw;
}

.right_header{
	align-items: center;
}

.button{
	font-size:var(--size_3);
	padding:0.5vh 1vw;
	margin-left:1vw;
	border-radius:3vh;
	border:1px solid black;
	cursor:pointer;
}

.button.button_full{
	border-color:var(--color);
	background: var(--color);
	color:white;
}

/* profile */

.header_profile{

}

.container_profile{
	border-radius:3vh;
	align-items:center;
	padding:0.5vh;
	cursor:pointer;
}

.image_profile{
	height:3vh;
	width:3vh;
	border-radius:50%;
	overflow:hidden;
	align-items: center;
	justify-content: center;
}

.name_profile{
	color:white;
	font-size:var(--size_3);
	margin:0 0.5vw;
	font-weight:500;
}

.menu_profile{
	flex-direction:column;
	position:absolute;
	background:white;
	top:7vh;
	right:0;
	box-shadow: var(--shadow);
}

.nav_profile{
	padding:1.5vh 1vw;
	align-items: center;
	font-size:var(--size_3);
	cursor:pointer;
}

.nav_profile:not(:last-child){
	border-bottom:1px solid var(--border);
}

.nav_profile img{
	height:2vh;
	width:2vh;
	margin-right:1vw;
}




/* color header */
.color_header .background_header{
	opacity:1;
	background: linear-gradient(to right, rgba(130, 120, 249, 1), rgba(167, 160, 251, 1));
}

.color_header .logo_header g, .color_header .logo_header path{
	color:white;
	fill:white;
}

.color_header .header_block{
	color:white;
}

.color_header .recruteur{
	color:white;
}


/* recruteur */
.label_recruteur{
	align-self: flex-end;
	color:var(--color);
	font-weight:600;
	font-size:var(--size_2);
	margin-left:0.5vw;
}

.color_header .label_recruteur{
	color:white;
}

.logo_cab{
	padding:1vh;
	background-color: white;
}