#log_in{
	display:flex;
	flex:1;
	flex-direction:column;
	align-items:center;
	justify-content: center;
	background-color: var(--color);
}

.input_field{
	margin:20px;
	width:100%;
	display:flex;
	justify-content: center;
}

#log_in .button{
	background-color: white;
}

.input_field input{
	border-radius:10px;
	padding:15px;
	width:20%;
	min-width:200px;
	border:none;
	border:1px solid var(--grey_border);
	outline:none;
	font-size:15px;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:var(--font);
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:var(--font);
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:var(--font);
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:var(--font);
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:var(--font);
}

::placeholder { /* Most modern browsers support this now. */
   color:var(--font);
}