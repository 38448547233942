.container_user{
  padding:2vh 1vw;
  align-items:center;
}

.infos{
  align-items:center;
  flex:2;
}

.image{
  height:5vh;
  width:5vh;
  align-items: center;
  justify-content: center;
  margin-right:2vw;
  overflow:hidden;
  border-radius:50%
}

.name{
  font-size:var(--size_3);
}

.info{
  flex:1;
  font-size:var(--size_3);
}