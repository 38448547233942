#missions .content_section {
  flex-direction: row;
}

#missions .container_content {
  width: 40%;
  margin-left: 5vw;
  align-items: flex-start;
  justify-content: center;
}

#missions .item_content {
  width: 100%;
}

#missions .content {
  transform: none;
  justify-content: center;
}

.container_missions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5vh;
}

.mission {
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  border-radius: 3vh;
  width: 47.5%;
  height: 6vh;
  overflow: hidden;
  -webkit-transition: height 1s ease, box-shadow 0.5s ease;
  transition: height 1s ease, box-shadow 0.5s ease;
  cursor: pointer;
  margin-bottom: 2vh;
}

.mission.show_mission {
  overflow: visible;
}

.mission:nth-child(odd) {
  margin-right: 5%;
}

.mission:hover {
  /*opacity:0.7;*/
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07), 0 3px 12px 0 rgba(0, 0, 0, 0.06),
    0 5px 25px 0 rgba(0, 0, 0, 0.04);
}

.header_mission {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--font);
  flex-shrink: 0;
}

.img_mission {
  height: 4vh;
  width: 4vh;
  border-radius: 50%;
  margin: 1vh;
  margin-right: 1vw;
}

.name_mission {
  flex: 1;
  font-size: 1.9vh;
  font-weight: 500;
  line-height: 2vh;
}

.arrow_mission img {
  height: 2vh;
  width: 2vh;
  margin: 1vh;
  margin-right: 2vw;
}

.text_mission {
  padding: 2vh;
  color: var(--font);
  font-size: 2vh;
  padding-top: 1vh;
  font-weight: 500;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
  opacity: 0;
  flex-shrink: 0;
}

.arrow_mission img {
  -webkit-transition: transform 1s ease;
  transition: transform 1s ease;
}

@media only screen and (max-width: 600px) {
  #missions .content_section {
    flex-direction: column;
  }

  .container_missions {
    justify-content: center;
  }

  .mission {
    width: 80%;
    margin-right: 0 !important;
  }

  #missions .container_content {
    width: 100%;
    margin-left: 0;
    margin-top: 5vh;
  }
}
