.container_field{
  flex-direction: column;
  align-self:stretch;
  padding:1vh;
  margin-bottom:2vh;
	box-sizing: border-box;
	width:100%;
}

.container_label_field{
  margin-bottom:1vh;
	justify-content: space-between;
}

.label_field{
  font-size:var(--size_3);
	align-items: center;
}

.container_input{
  position:relative;
}

.container_field_input{
	flex:1;
	border:1px solid var(--border);
	border-radius:1vh;
	padding:1.5vh 2vh;
  background-color:white;
  z-index: 2;
	position:relative;
}

.container_field_input textarea{
	border:1px solid var(--border);
	border-radius:1vh;
	min-height:10vh;
	align-self:stretch;
	flex:1;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	background: none;
}


.container_field_input input, .container_field_input textarea{
	flex:1;
	border:none;
	min-width:0;
	font-size:var(--size_3)
}

.error_input input, .error_input textarea{
	border-color:var(--red);
}

.container_input textarea{
	flex:1;
	min-height:10vh;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.label_missing{
	color:var(--red);
}

.icon{
	height:2vh;
	width:2vh;
	margin-right:1vw;
}

@media only screen and (max-width: 600px) {
	.container_field{
		width:100% !important;
	}
}
