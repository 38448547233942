#body_forgot{
	flex:1;
	flex-direction:column;
	align-items:center;
	justify-content: center;
	align-self:center;
}

#forgot_label{
	flex-direction: column;
	margin-bottom:5vh;
}

#forgot_label div{
	margin-bottom:2vh;
	color:var(--purple);
	font-size:2.5vh;
	font-weight:bold;
}

#forgot_label input{
	border:none;
	background-color: #f5f5f5;
	border-radius:2vh;
	padding:1vh 2vh;
}

@media only screen and (max-width: 600px) {
	#body_forgot{
		flex:none;
		min-height:50vh;
		padding:10vh;
	}

}
