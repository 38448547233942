.outer_search{
  flex-direction:column;
  margin:1vh;
}

.container_search{
  align-self:stretch;
  flex-direction:row;
}

.searchbar{
  background-color: white;
  border-radius:3vh;
  padding:1.5vh 0.5vw;
  flex:1;
  align-items:center;
  font-size:var(--size_3);
  border:1px solid var(--border);
}

.searchbar input{
  border:none;
  flex:1;
  width:0;
}

.searchbar input::placeholder{
  font-size:var(--size_3);
}

.button_filter{
  border:1px solid var(--color);
  color:var(--color);
  border-radius:1vh;
  font-size:var(--size_3);
  align-items:center;
  justify-content: center;
  padding:0 1.5vw;
  margin-left:1.5vw;
}

.searchbar img{
  height:2vh;
  width:2vh;
  margin:0 0.5vw;
}

.filters{
  flex-direction: column;
}

.reset_filters{
  height:3vh;
  align-items: center;
  justify-content: flex-end;
}

.label_reset_filters{
  color:var(--color);
  font-size:var(--size_3);
  margin-left:1vw;
}

.image_reset{
  height:2vh;
  width:2vh;
}

@media only screen and (max-width: 600px) {
  .searchbar{
    border:none;
    padding:1vh 5vw;
  }

  .outer_search{
   margin:0;
   padding:1vh;
  }

  .searchbar input{
    padding:1vh 0;
  }

  .searchbar img{
    margin-right:5vw;
  }

  .icon_filter{
    height:3vh;
    width:3vh;
    border-radius:50%;
    overflow:hidden;
    background-color: var(--grey);
    align-items:center;
    justify-content: center;
  }

  .icon_filter.active{
    background-color: var(--color);
  }

  .bannerButtons{
    padding:2vh 4vw;
    border-top:1px solid var(--border);
    justify-content: flex-end;
  }
}