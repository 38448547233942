.container_translate{
	position:relative;
	height: 100%;
    flex: 1;
    padding-left:0.5vw;
}

.item_translate{
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content: center;
    position:absolute;
    -webkit-transition: transform 1s ease, opacity 1s ease;
    transition: transform 1s ease, opacity 1s ease;
    color:var(--sub_color);
}