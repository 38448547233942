.container_switch {
  flex-direction: column;
}

.row {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.switch {
  /*margin-bottom:1vh;
	for switch profile
	*/
}

.switch {
  position: relative;
  align-self: flex-start;
  cursor: pointer;
}

.container {
  background: var(--grey);
  color: white;
  font-size: var(--size_3);
  padding: 0.5vh 0.5vw;
  font-weight: 500;
  border-radius: 2vh;
  width: 7.5vh;
  height: 3vh;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
}

.button {
  position: absolute;
  left: 0;
  top: 0.25vh;
  width: 2.5vh;
  height: 2.5vh;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6);
  -webkit-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  cursor: pointer;
}

.checked .container {
  justify-content: flex-start;
  background: var(--color);
}

.checked .button {
  transform: translateX(5vh);
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0.5vh 2vw;
  }
}
