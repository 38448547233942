.container_field {
  align-items: flex-start;
  flex-shrink: 0;
}

.containerOptions {
  flex-direction: row;
  border-radius: 1vh;
  border: 1px solid var(--border);
  overflow: hidden;
}

.option {
  padding: 1.5vh 1vw;
  font-size: var(--size_3);
  color: var(--font);
  border-right: 1px solid var(--border);
}

.option:last-child {
  border: none;
}

.selectedOption {
  background-color: var(--color);
  color: white;
}
