.contentModal {
  z-index: 2;
  height: 90%;
  width: 45%;
  align-self: center;
  background-color: white;
  border-radius: 0.5vh;
  box-shadow: var(--shadow_3);
  overflow: hidden;
  flex-direction: column;
  position: relative;
}

.modalShrink {
  height: auto;
  max-height: 80%;
  width: auto;
  width: 40vw;
}

.backgroundModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
}

.simpleModal {
  background-color: transparent;
  width: auto;
  align-items: center;
  justify-content: center;
}

.container_header {
  padding: 2vh 6vh;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
}

.banner_button {
  padding: 2vh 6vh;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border);
}

.label_header {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
}

.label_header span {
  text-overflow: ellipsis;
  min-width: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

@media only screen and (max-width: 600px) {
  .contentModal {
    height: 100%;
    width: 100% !important;
    border-radius: 0;
  }

  .container_header {
    font-size: var(--size_2);
    padding: 2vh 4vw;
  }
}
