.container_form_view{
  flex:1;
  flex-direction:row;
  overflow:hidden;
}

.side_menu{
  flex-direction:column;
  width:25%;
  align-self:stretch;
}

.container_infos_modif{
  flex-direction:column;
}

.container_menu_modif{
  overflow:hidden;
}

.active{
  color:var(--color);
}




.item_menu{
  padding:1vh 0.5vw;
  font-size:var(--size_3);
  align-items: center;
  cursor:pointer
}

.item_menu:first-child{
  padding-top:2vh;
}

.item_menu:last-child{
  padding-bottom:2vh;
}

.item_menu:hover .name{
  color:var(--dark_color)
}

.item_menu:hover .index{
  background-color:var(--dark_color);
  color:white;
}

.index{
  color:var(--grey);
  background-color: var(--light_grey);
  border-radius:50%;
  height:3vh;
  width:3vh;
  font-size:var(--size_4);
  align-items: center;
  justify-content: center;
  font-weight:500;
  margin-right:1vw;
  flex-shrink:0;
  box-sizing: border-box;
}

.active .index, .active.index{
  background-color: var(--color);
  color:white;
}

.index path{
  fill:var(--grey)
}

.active .index path, .active.index path, .item_menu:hover .index path{
  fill:white;
}



.container_info_admin{
  color:var(--font);
  font-size:var(--size_4);
  align-items: center;
  padding:1vh;
  white-space: pre;
}

.container_info_admin img{
  height:2vh;
  width:2vh;
  margin-right:1vh
}

.container_buttons{
  padding:1vh;
  justify-content: space-evenly;
}

.name{
  flex:1
}

.missing{
  margin-right:0.5vw;
  margin-left:1vw;
  background-color:red;
  height:2vh;
  width:2vh;
  font-size:1.2vh;
  line-height:2vh;
}