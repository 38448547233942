#container_searchbar .content_list{
	flex-direction:column;
	padding:0;
	flex-shrink: 0;
}

.container_list_search{
	box-shadow: var(--shadow);
	border:none;
}

.container_field_search input{
	flex:1;
	border:none;
	padding:1vh 0;
}

#search_field input{
	padding-left:1vw;
}



@media only screen and (max-width: 600px) {
	#search_field input{
		padding-left:2vw;
	}

	#container_searchbar.show_list_1, #container_searchbar.show_list_2{
		/*position:static;*/
		/*margin-top:-4vh;*/
	}

	/*
	#container_searchbar.show_list_1 #searchbar, #container_searchbar.show_list_2 #searchbar{
		width:100vw;
		box-shadow:none;
		padding:0;
	}

	.container_search_select{
		max-height:none;
		bottom:0;
		top:15vh;
	}

	.show_list_2 #search_type, .show_list_1 #search_field, .show_list_2 #search_field, .show_list_1 #search_size{
		display:none;
	}

	.show_list_1 #search_type, .show_list_2 #search_size{
		border-top:1px solid var(--sub_color);
		border-bottom:1px solid var(--sub_color);
		border-left:none;
		height:8vh;
		margin:0;
	}

	#container_header_search .refresh{
		padding-left: 3vw;
		padding-right: 3vw;
	}

	#container_header_search .number_selected{
		margin-right:2vh;
	}*/

	.pop_up .container_search_select{
		max-height:none;
		bottom:auto;
		top:100%;
	}

	.pop_up .container_cab_logo{
		width:15vw;
	}

	/*#container_list_cabs #container_header_search .content_list{
		justify-content: flex-start;
		padding-top:0;
	}*/

}