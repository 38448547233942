.outer_article{
  position:absolute;
  top:0;
  bottom:0;
  left:-2px;
  right:-2px;
  z-index:10;
  margin:var(--margins);
  margin-top:7vh;
  margin-bottom:0;
}

@media only screen and (max-width: 600px) {
  .outer_article{
    margin:0;
  }
}