.card_pie {
  flex: 1;
  flex-direction: row;
}

.container_details_pie {
  flex-direction: column;
  justify-content: space-evenly;
  width: 20vw;
}

.details_pie {
  flex-direction: column;
  padding: 0 2vw;
}

.item_details_pie {
  flex-direction: row;
  align-items: center;
}

.color_pie {
  height: 1vh;
  width: 1vh;
  border-radius: 50%;
  margin-right: 1vw;
  flex-shrink: 0;
}

.label_pie {
  font-size: var(--size_2);
}

.visitors_pie,
.connections_pie {
  font-size: var(--size_3);
  margin-bottom: 1vh;
}

.connections_pie {
  color: var(--font);
}

.row_details {
  flex-direction: row;
}

.card_detail {
  height: 25vh;
  flex: 1;
  flex-direction: column;
  padding: 1vh 1vw;
}

.card_title {
  font-size: var(--size_3);
  color: var(--font);
  margin-bottom: 1vh;
}

.item_bs {
  flex: 1;
  align-self: stretch;
}

.container_item_bs {
  flex-direction: column;
  flex: 1;
}

.container_bar_bs {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.container_value_bs {
  font-size: var(--size_3);
  margin-bottom: 0.5vh;
}

.bar_bs {
  background: var(--color);
  width: 2vw;
}

.label_bs {
  font-size: var(--size_3);
  color: var(--font);
  margin-top: 1vh;
  align-self: center;
}

.line_stat {
  flex-direction: row;
}

.item_stat {
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.label_stat {
  font-size: var(--size_3);
  color: var(--font);
  margin-bottom: 1vh;
}

.figure_stat {
  font-size: var(--size_3);
}

.container_stat {
  flex-direction: column;
  flex: 1;
  justify-content: space-evenly;
}

.separator {
  border-bottom: 1px solid var(--border);
}

.item_sub_details_pie {
  width: 100%;
  justify-content: space-between;
  margin: 5px 0;
}

.item_sub_details_pie .label_pie {
  font-size: 10px;
}

.reconnexion .color_pie {
  opacity: 0.3;
}

.reconnexion,
.first_connexion {
  align-items: center;
}

.loading_image {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.loading_image img {
  height: 50px;
  width: 50px;
}
