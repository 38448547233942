/* actions */
.action{
  padding:1vh;
  height:2vh;
  width:2vh;
}

.active g, .active path{
  fill:var(--color);
  opacity:1;
  color:var(--color);
}
