.container_homepage{
	display:flex;
	flex-direction:column;
	align-self:stretch;
}

.container_section_homepage{
	display:flex;
	flex-direction:column;
	padding:5vw;
	flex-shrink:0;
}

.container_section_homepage .title_section{
	
}

.container_list_home{
	display:flex;
	flex-direction: row;
	flex-wrap:wrap;
	justify-content: space-evenly;
}



.container_title_section{
	align-items:center;
	margin-bottom:5vh;
	font-family:"Futura";
	letter-spacing: 0.4px;
}

.title_section{
	flex:1;
}

.container_more{
	display:flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight:600;
	color:var(--sub_color);
	font-size:1.8vh;
	align-self: flex-end;
	white-space: nowrap;
		font-family:"Futura";
	letter-spacing: 0.4px;
}

.container_more:hover{
	opacity:0.7;
}

.container_more img{
	height:1.8vh;
	margin-left:1.8vh;
}


@media only screen and (max-width: 600px) {
	.container_title_section{
		flex-direction:column;	
	}

	.container_title_section .title_section{
		text-align:center;
		margin-bottom:2vh;
	}

	.container_more{
		align-self:center;
	}

	.container_list_home{
		flex-direction:column;
		align-items:center;
		width: 100%;
	}
}