.headerCab{
  position:relative;
  flex-direction:row;
  justify-content: space-between;
  padding:5vh;
  background: white;
}

.cover{
  position:absolute;
  left:15vw;
  top:0;
  bottom:0;
  right:0;
}

.card{
  position:relative;
  width:30%;
  z-index:2;
  flex-direction: column;
  padding:4vh;
  box-shadow:var(--shadow);
}

.backgroundCard{
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
  background: white;
  z-index:-1;
}

.containerInfos{
  flex-direction:row;
  align-items: center;
  margin-bottom:2vh;
}

.logo{
  height:10vh;
  width:10vh;
  align-items: center;
  justify-content: center;
  margin-right:2vw;
}

.infosHeader{
  flex-direction: column;
  flex:1;
}

.name{
  font-size:var(--size_1);
  margin-bottom:1vh;
  font-weight:500;
  align-items:center;
}

.intro{
  font-size:var(--size_3);
}

.sn{
  background: white;
  border-radius:1vh;
  flex-direction: row;
  z-index:2;
  align-items: center;
  padding-top:2vh;
}

.item_sn{
  margin-right:1vw;
  align-items: center;
  justify-content: center;
}

.logo_sn{
  height:1.8vh;
  max-width:5vw;
}

.name img{
  height:2vh;
  width:2vh;
}
@media only screen and (max-width: 600px) {
  .backgroundCard{
    opacity:0.9
  }

  .cover{
    left:0;
  }

  .headerCab{
    flex-direction: column;
  }

  .card{
    width:100%;
    box-sizing:border-box;
  }

  .logo{
    margin-bottom:2Vh;
  }

  .sn{
    align-self:center;
    box-sizing:border-box;
    margin-top:2vh;
    padding:2vh;
  }

  .item_sn{
      margin:0 2vw
  }

  .logo_sn{
    max-width:10vw;
  }

  .containerInfos{
    flex-direction: column;
    flex-shrink:0;
  }
  .labelName{
    flex-shrink:0;
  }

  .info_min_cab{
    flex-direction: column;
    justify-content:center;
  }

  .container_info_min_cab{
    font-size:var(--size_2);
    margin:0.5vh 0;
  }

  .container_info_min_cab img{
    height:4vh;
    width:4vw;
    margin-right:3vw;
  }

  .label_info{
    align-items: center;
  }
}