.container_table{
  flex-direction:column;
}

.container_header{
  flex-direction:row;
  border-bottom:1px solid var(--border);
}

.container_header .item:not(:first-child){
  font-weight:500;
  color:black
}

.container_header .item, .item_number{
  font-size:var(--size_2);
  color:var(--font);
}

.item{
  padding:2vh 0;
}

.item{
  align-items: center;
  justify-content: center;
  font-size:var(--size_2);
  flex-direction: column;
  padding-left:1vw;
  padding-right:1vw;
}

.item_recruit .item_type, .item_recruit .item_critere{
  align-items: flex-start;
}

.item_number{
  flex:1;
  border-right:1px dotted var(--border);
}

.item_type{
  flex:2;
  border-right:1px dotted var(--border);
}

.item_critere{
  flex:3
}

.inner_table{
  flex-direction:column;
}

.item_recruit:not(:first-child){
  border-top:1px solid var(--border);
}

.hide{
  display:none
}

.banner{
  background-color: var(--color);
  color:white;
  padding:1vh 0;
  font-size:var(--size_2);
  align-items: center;
  justify-content: center;
}

.text{
  flex-direction:column;
}

@media only screen and (max-width: 600px) {
  .item_type, .item_critere{
    padding:0 1vw;
  }

  .item_number{
    padding:2vh 0;
    flex:1;
  }

  .item_type{
    flex:3;
  }
  
  .item_critere{
    flex:4;
  }

  ul{
    padding-inline-start:25px;
  }
}
