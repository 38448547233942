.container_banner_cab{
  position: relative;
  min-height:25vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.container_background{
  height:100%;
  width:100%;
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
}

.overlay_background{
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:var(--color);
  z-index:2;
  opacity:0.6;
}

.image_banner_cab{
  min-height:100%;
  min-width:100%;
  object-fit:cover;
}

.content_banner_cab{
  z-index:2;
  align-items:center;
  justify-content: space-evenly;
  flex-direction:column;
  min-height:25vh;
}

.container_infos_cab{
  align-items:center;
  justify-content: center;
  flex-direction:column;
}

.container_logo{
  height:4vh;
  width:4vh;
  padding:1vh;
  align-items: center;
  justify-content: center;
}

.image_logo_cab{
  max-height:100%;
  max-width:100%;
  object-fit: contain;
}

.name_cab{
  color:white;
  font-size:var(--size_4);
  margin-top:1vh;
}

.title_banner_cab{
  font-size:var(--size_2);
  font-weight:bold;
  color:white;
  margin:0 4vw;
  text-align:center;
}


/* offer */ 
.container_infos_offre{
  justify-content: space-evenly;
}

.item_infos_offre{
  align-items: center;
  margin:0 1vw;
}

.image_infos_offre{
  height:2vh;
  width:2vh;
  align-items: center;
  justify-content: center;
  margin-right:0.5vw;
}

.label_infos_offre{
  color:white;
  font-size:var(--size_3)
}

.close{
  z-index:3;
  position:absolute;
  top:2vh;
  right:1vh;
  height:2vh;
  width:2vw;
  cursor:pointer;
}





.progress_image{
  background-color: white;
  height:8vh;
  width:8vh;
  border-radius:50%;
  border:1px solid white;
  overflow:hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.progress_image:first-child{
  padding:1vh;
  margin-right:-1vh;
}

@media only screen and (max-width: 600px) {
  .close{
    margin-right:2vw;
    right:2vw;
    height:3Vh;
    width:3vw;
  }
}
