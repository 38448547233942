.card_matching{
	flex-direction:column;
	padding:3vh;
	margin-left:1vw !important;
	margin-right:1vw !important;
}

.card_matching.card_waiting{
	background-color: #ECEAEA;
}

.header_card_matching{
	justify-content: space-between;
	align-items: center;
}

.contrat_card_matching{
	background-color:#F2F2F2;
	padding:1vh 2vh;
	border-radius:2vh;
	color:var(--font);
	font-size:var(--size_4);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	min-width: 0;
	display: block;
}

.contrat_date_matching{
	color:var(--font);
	font-size:var(--size_3);
}


.user_card_matching{
	margin-top:3vh;
	margin-bottom:2vh;
	align-items:center;
}


.img_card_matching{
	height:5vh;
	width:5vh;
	border-radius:50%;
	overflow:hidden;
}

.img_card_matching img{
	min-height: 100%;
	min-width:100%;
	object-fit: cover;;
}

.img_card_matching_blur{
	-webkit-filter: blur(0.45vh); 
  	filter: blur(0.45vh);
}



.container_name{
	flex-direction: column;
	margin-left:1vw;
}

.name_card_matching{
	font-size:var(--size_3);
	margin-bottom:1vh
}

.details_card_matching{
	flex-direction:column;
	margin:1vh 0;
}

.item_card_matching{
	margin:1vh 0;
}

.container_label_card_matching{
	color:var(--font);
	font-size:var(--size_3);
	margin-left:1vw;
}

.icon_card_matching{
	height:2vh;
	width:2vh;
	align-self: center;
}
