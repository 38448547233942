.container_field{
	padding:1vh;
	flex-direction: column;
}

.container_label_field{
	margin-bottom:1vh;
}

.label_field{
	font-size:2vh;
}

.unit{
	background-color: var(--background);
	font-size:2vh;
	margin:-1.5vh -2vh;
	align-items: center;
	justify-content: center;
	padding:0 2vh;
	font-size:var(--size_3);
	color:var(--font);
	border-radius:0 1vh 1vh 0;
}


.item_missing{
	border-color:var(--red);
}
