.container_button_cv{
  position:relative;
  align-self:stretch;
}

.input_cv{
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  opacity:0;
  width:100%;
}

.loader{
  height:3vh;
  border:1px solid var(--color);
  border-radius:1h;
  align-items:center;
  flex:1;
  align-self:stretch;
}
