.container_item_content{
	display:flex;
	flex-direction:row;
	border-radius: 1vh;
	align-items:center;
	justify-content: center;
	position:relative;
}

.item_content{
	border:1px solid var(--grey_border);
	margin:2vh;
	flex: 1;
	align-self: stretch;
	border-radius:1vh;
	flex-direction:column;
	align-items:center;
	justify-content: center;
	position:relative;
	overflow:hidden;
}

.container_item_content_show .item_content{
	overflow:visible;
}

.remove_image{
	position:absolute;
	top:1vh;
	right:1vh;
	height:3vh;
	width:3vh;
}

.field_content{
	flex-direction:column;
	align-self:stretch;
	align-items:center;
	justify-content: center;
}

.separator{
	font-size:1.8vh;
	color:var(--font);
	align-self: center;
	opacity:0.7;
	margin-top:-2vh
}

.field_content .outer_input, .field_content .container_select{
	margin:1vh 2vh;
	align-self:stretch;
}

.field_content .container_photos{
	align-self:center;
}




.container_video{
	background-color: black; align-items: center; justify-content: center;
}

.outer_video{
	width:100%;
}

.container_image_content{
	height:100%;
	width:100%;
	overflow:hidden;
}

.container_image_content img{
	height:100%;
	width:100%;
	object-fit:cover;
}

.container_item_content .remove_image{
	top:1vh;
	right:1vh;
}



.cab_data{
	font-size:var(--size_2);
	color:var(--font);
	font-weight:500;
}




.article{
	flex-direction:column;
	height:100%;
	width:100%;
	align-items: center;
}

.image_article{
	height:50%;
	width:100%;
	align-items: center;
	justify-content: center;
	overflow:hidden;
}

.name{
	color:var(--font);
	font-size:var(--size_3);
	padding:2vh;
	text-align:center;
	align-items: center;
	justify-content: center;
	flex:1;
}