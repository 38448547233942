.container_offer {
  padding: 2vh 2vw;
  margin: 2vh 10px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container_switch {
  margin: 0 1vw;
}

.container_description {
  flex: 2;
  flex-direction: column;
  margin: 0 1vw;
}

.container_title {
  font-size: var(--size_2);
}

.container_details {
  font-size: var(--size_3);
  color: var(--font);
}

.container_views {
  flex: 0.5;
}

.views {
  font-size: var(--size_3);
  color: var(--font);
}

.container_candidates {
  flex: 1;
}

/* user */

.container_offre {
  background: white;
  border-radius: 1vh;
  margin: 1vh;
  padding: 4vh 1.5vw;
  align-items: center;
}

.container_icon {
  width: 5vw;
  height: 5vh;
  align-items: center;
  justify-content: center;
  margin-right: 2vw;
}

.container_icon img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.infos_offre {
  flex: 5;
  align-items: flex-start;
  flex-direction: column;
}

.container_name {
  font-size: var(--size_3);
  margin-bottom: 1vh;
}

.title_offre {
  font-size: var(--size_3);
  font-weight: 500;
}

.details {
  margin-top: 1vh;
  white-space: nowrap;
}

.extraContent {
  flex: 2;
  justify-content: flex-end;
}

.extraContentApply {
  flex-direction: column;
  flex: 2;
}
/* progress */
.progress_bar {
  background-color: var(--background);
  align-self: stretch;
  border-radius: 2vh;
  height: 1vh;
  position: relative;
  margin-top: 0.5vh;
  overflow: hidden;
}

.inner_progress_bar {
  background-color: var(--color);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 2vh;
}

.container_steps {
  color: var(--color);
  font-size: var(--size_3);
  margin-left: 1vw;
}

.details_offre {
  flex: 1;
}

.item_details_offre {
  font-size: var(--size_3);
  color: var(--font);
  padding-right: 1vw;
  flex-wrap: wrap;
}

/* types */
.stoped .container_steps {
  color: var(--grey);
}

.stoped .inner_progress_bar {
  background-color: var(--grey);
}

.finished .container_steps {
  color: var(--green);
}

.finished .inner_progress_bar {
  background-color: var(--green);
}

.declined .container_steps {
  color: var(--red);
}

.declined .inner_progress_bar {
  background-color: var(--red);
}

.offre_over {
  position: relative;
  cursor: default !important;
}

.container_offre.spontaneous {
  background-color: var(--light_grey);
}

/* candidates */
.container_candidates {
  align-items: center;
}

.images_candidates {
  align-items: center;
  width: 6vh;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.image_candidate {
  height: 3vh;
  width: 3vh;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--light_grey);
}

.image_candidate:nth-child(2) {
  margin-left: -0.5vh;
  z-index: 2;
}

.more_candidate {
  background-color: var(--light_grey);
  font-size: 1.4vh;
  align-items: center;
  justify-content: center;
  line-height: 3vh;
  margin-top: -1vh;
  z-index: 3;
}

.label_candidate {
  font-size: var(--size_3);
  margin-left: 1vw;
  color: var(--font);
}

.previewCV {
  box-shadow: none !important;
  flex: 1;
  padding: 1vh;
}

.detailsRefused {
  position: absolute;
  background: white;
  top: 0;
  right: 0;
  box-shadow: var(--shadow);
  font-size: var(--size_3);
  padding: 2vh 2vw;
  display: none;
}

.offre_over:hover .detailsRefused {
  display: flex;
}

.delete {
  font-size: 10px;
  color: var(--red);
}

.new_candidate {
  background-color: var(--red);
  height: 15px;
  width: 15px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
}

.previewCV .new_candidate {
  display: none;
}

.container_offre:hover .stop_explanation{
  display:flex;
}

.stop_explanation{
  box-shadow: var(--shadow);
  position:absolute;
  background-color: white;
  right:10px;
  padding:10px 20px;
  flex-direction:column;
  min-width:100px;
  top:-10px;
  display:none;
}

.title_stop_explanation{
  font-size:14px;
  font-weight:bold;
}

.details_stop_explanation{
  font-size:14px;
  margin-top:5px;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .infos_offre {
    flex-direction: column;
    align-items: flex-start;
  }

  .container_icon {
    width: 15vw;
    height: 3vh;
    flex-shrink: 0;
  }

  .container_offre {
    padding: 2vh 2vw;
    width: calc(100vw - 2vh);
    box-sizing: border-box;
  }

  .details {
    flex-wrap: wrap;
  }
}
