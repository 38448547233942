.container_tab{
  box-shadow:var(--shadow);
  flex-direction: row;
  background: white;
  position: -webkit-sticky;
	position: sticky;
  top: 7vh;
  z-index:3;
  
}

.item_tab{
  flex:1;
  padding:3vh 0;
  align-items: center;
  justify-content: center;
  font-size:var(--size_2);
  border-bottom:2px solid transparent;
}

.active{
  color:var(--color);
  border-bottom:2px solid var(--color);
}

@media only screen and (max-width: 600px) {
  .container_tab{
    overflow-x:scroll;
    padding:0 2vw;
    top:0;
  }

  .item_tab{
    flex:none;
    margin:0 2vw;
  }

}
