.context{
  align-items: center;
  margin:4vh 0;
  margin-bottom:6vh;
  width:100%;
}

.image{
  height:3vh;
  align-items: center;
  justify-content: center;
}

.text{
  font-size:var(--size_3);
  color:var(--font);
  margin-left:1vw;
  flex:1;
}