#menu{
	width:18vw;
	flex-direction:column;
	padding-top:2vh;
	position:relative;
	border-right:1px solid var(--grey_border);
	color:var(--font);
	align-self:stretch;
	font-size:2.2vh;
}

#menu a{
	text-decoration: none;
	margin:2vh;
}

#menu span{
	margin:2vh;
	cursor:pointer;
	color:var(--font);
}

#menu span:hover{
	opacity:0.7;
}

#menu img{
	height:4vh;
	width:4vh;
}

.container_logo_menu{
	align-self:center;
	justify-content: center;
	display:flex;
}




.container_list_menu{
	flex-direction:column;
}

.title_list_menu{
	margin:2vh;
	margin-left:4vh;
	cursor:default;
}

.container_item_list_menu{
	flex-direction:column;
	margin-left:3vh;
}

#menu .container_item_list_menu a{
	font-size:1.8vh;
	margin:1vh;
}

#version{
	position:absolute;
	bottom:2vh;
	right:2vh;
	font-size:1.5vh;
	opacity:0.7;
}