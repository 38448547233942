.container_subscribe{
  margin-top:3vh;
}

.text_subscribe{
  font-size:var(--size_2);
  text-align:center;
  align-self: center;
  margin-top:2vh;
}

.intro_image{
  height:20vh;
}

.container_info_linkedin{
  align-self:center;
  align-items: center;
  justify-content: center;
  position:relative;
}

.container_info_linkedin img{
  height:2vh;
  width:2vh;
  margin-right:1vw;
}

.label_info_linkedin{
  color:var(--font);
  font-size:var(--size_3);
}

.popup_info_linkedin{
  display:none;
  position:absolute;
  width:100%;
  bottom:3vh;
  background: var(--light_grey);
  flex-direction: column;
  padding:3vh 2vh;
  font-size:var(--size_3);
}

.container_info_linkedin:hover .popup_info_linkedin{
  display:flex;
}

.popup_info_linkedin div{
  margin:1vh 0;
}


