.item{
	background-color: white;
	box-shadow:var(--shadow_list);
	margin:1vh;
	padding:2vh 1.5vw;
	border-radius:1vh;
	align-items: center;
}

.label_item{
	font-size:var(--size_3);
	flex:1
}

.container_action{
	padding:0.5vh 0.5vw;
}

.container_form{
	flex-direction:column;
	flex: 1;
  overflow: hidden;
}

.container_header{
	padding:3vh;
	align-items:center;
}

.container_label_header{
	flex:1;
	font-size:var(--size_2);
}


.status_item_select{
	font-size:var(--size_3);
	color:var(--font);
}