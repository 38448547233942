.container_header{
  justify-content:flex-end;
  margin:2vh 1vw;
  margin-top:4vh;
}

.container_access{
  align-items: center;
  padding:2vh 1vw;
}

.name, .email, .last_connect{
  font-size:var(--size_3);
  flex:1
}

.image{
  background-color: var(--light_grey);
  border-radius:50%;
  height:3vh;
  width:3vh;
  margin-right:1vw;
  padding:1vh;
}

.delete{
  background-color: var(--red);
  border-radius:50%;
  height:1vh;
  width:1vh;
  margin-right:1vw;
  padding:1vh;
}