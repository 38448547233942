.container_list_articles {
  margin: var(--margins);
}

/* main article */
.container_main_article {
  width: 100%;
  position: relative;
  align-items: center;
  margin-bottom: 60px;
}

.image_main_article {
  width: 100%;
  height: 40vh;
  border-radius: 1vh;
  overflow: hidden;
}

.content_main_article {
  position: absolute;
  flex-direction: column;
  padding: 3vh 2vw;
  bottom: -50px;
  left: 50px;
  right: 50px;
  background-color: transparent !important;
  z-index: 1;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  opacity: 1;
  z-index: -1;
}

.title_main_article {
  font-size: var(--size_2);
  font-weight: 600;
  font-family: "Futura";
}

.abstract_main_article {
  font-size: var(--size_2);
  margin: 2vh 0;
}

.date_main_article {
  font-size: var(--size_3);
  color: var(--font);
}

@media only screen and (max-width: 600px) {
  .container_list_articles {
    margin: 0;
  }
  .image_main_article {
    border-radius: 0;
  }
  .content_main_article {
    width: 80vw;
    left: 10vw;
    bottom: auto;
    padding: 2vh 4vw;
    margin: 0 !important;
    box-sizing: border-box;
  }
  .background {
    opacity: 0.8;
  }
}
