/* button buddy scna */
.button_buddyscan {
  background: linear-gradient(90deg, #8278f9 0%, #9f97f9 94.67%);
  border-radius: 3vh;
  padding: 1vh;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
}

.image_buddyscan {
  background-color: white;
  height: 3vh;
  width: 3vh;
  border-radius: 50%;
  padding: 0.5vh;
  margin-right: 0.5vw;
}

.image_buddyscan svg {
  height: 100%;
  width: 100%;
}

.image_buddyscan path {
  fill: var(--color);
}

.label_buddyscan {
  color: white;
  font-size: var(--size_2);
  margin-right: 2vw;
}

.white.button_buddyscan {
  background: white;
}

.white .image_buddyscan {
  background-color: var(--color);
}

.white .label_buddyscan {
  color: var(--color);
}

.white g,
.white path {
  fill: white;
}

@media only screen and (max-width: 600px) {
  .image_buddyscan {
    margin-right: 2vw;
  }
}
