.container_landing {
  flex: 1;
  align-self: stretch;
  flex-shrink: 0;
}

/* intro landing */

.intro_landing {
  background-color: var(--color);
  align-self: stretch;
  height: 80vh;
  padding: 10vh 5vw;
  padding-bottom: 0;
  flex-shrink: 0;
  flex-direction: row;
}

.image_landing {
  height: 100%;
  width: 40vw;
  align-items: flex-end;
}

.text_landing {
  flex-direction: column;
  flex: 1;
  justify-content: space-evenly;
  padding-bottom: 10vh;
  color: white;
  margin-top: 5vh;
  flex-shrink: 0;
}

.title_landing {
  font-size: 6vh;
  font-weight: 600;
  flex-shrink: 0;
}

.logo_landing {
  height: 8vh;
  width: 100%;
  margin-bottom: 5vh;
  flex-shrink: 0;
}

.description_landing {
  font-size: var(--size_2);
  flex-shrink: 0;
}

@media only screen and (max-width: 600px) {
  .intro_landing {
    flex-direction: column-reverse;
  }

  .button {
    margin-top: 4vh;
    justify-content: flex-end;
  }
}
