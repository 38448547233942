.quill {
  flex-direction: column;
  border: 1px solid var(--border);
  border-radius: 1vh;
}

.ql-editor {
  /* new*/
  max-height: none !important;
  flex-direction: column;
  flex: 1;
  min-height: 20vh;
}

.ql-editor p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: var(--size_3);
}

.singleLine .ql-editor {
  min-height: 0;
}

.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid var(--border);
  flex-wrap: wrap;
}

.outer_input_texteditor {
  flex-direction: column;
}

#pop_up_texteditor .preview_image_article {
  max-height: 20vh;
  max-width: 20vw;
  margin: 5vh 0;
}

#pop_up_texteditor .loading_image_article {
  height: 6vh;
  padding: 7vh 0;
  margin: 5vh 0;
}

#pop_up_texteditor {
  padding: 2vh;
  flex-direction: column;
  align-items: center;
}

#pop_up_texteditor input {
  border: 1px solid var(--grey_border);
  border-radius: 1vh;
  padding: 1vh 2vh;
  align-self: stretch;
  margin-bottom: 2vh;
}

#pop_up_texteditor .button {
  margin-top: 2vh;
}

.inline-img {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  align-self: center;
  margin-top: 5vh;
  overflow: hidden;
}

.inline-img img {
  max-height: 50vh;
  margin-bottom: 2vh;
  object-fit: contain;
  max-width: 100%;
  cursor: pointer;
}

.inline-img div {
  font-size: 1.8vh;
  color: var(--font);
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-style: normal;
  padding-top: 1em;
}

.quill a {
  display: inline;
}

.display .ql-editor {
  min-height: 0;
}

.display .quill {
  border: none;
}

/* MCB-168 */

.quill {
  max-height: 60vh;
  overflow: hidden;
}

.ql-container.ql-snow {
  overflow: hidden;
  flex: 1;
}

.display .quill {
  max-height: none;
}

.item_missing .quill {
  border-color: var(--red);
}

.ql-tooltip.ql-editing {
  left: 0 !important;
  right: 0 !important;
  display: flex;
}

.ql-tooltip.ql-editing.ql-hidden {
  display: none;
}

.ql-tooltip.ql-editing input {
  flex: 1;
}
