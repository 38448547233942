.container{
  flex-direction: column;
}

.intro_mission{
  flex-direction: row;
  align-items:flex-start;
  margin-bottom:4vh;
}

.text{
  font-size:var(--size_2);
  flex-direction:column;
  line-height:3vh;
  flex:1;
}

.missions{
  flex-wrap:wrap;
  flex:1;
}

@media only screen and (max-width: 600px) {
  .intro_mission{
    flex-direction: column;
  }
}