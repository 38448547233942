#container_admin_connect{
	background-color: var(--color);
	flex-direction:column;
	flex:1;
	align-self:stretch;
	align-items: center;
	justify-content: center;
}

.content_admin_connect{
	flex-direction: column;
	width:30vw;
	align-items: center;
}

.title_admin_connect{
	color:white;
	margin-bottom:10vh;
	font-weight:bold;
	font-size:var(--size_1);
}

.intro_admin_connect{
	color:white;
	margin-bottom:2vh;
	font-size:var(--size_2);
}

#container_admin_connect .container_field{
	padding-right:0;
	margin-bottom:5vh;
}

#container_admin_connect .button{
	margin-bottom:3vh;
}

#container_admin_connect .button_white{
	border:1px solid white;
}