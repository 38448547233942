.container_select{
	position:relative;
	flex:1;
	align-self: stretch;
}

.container_input_select{
	z-index:2;
	position:relative;
  flex:1;
  align-self: stretch;
}

.container_search_select{
	border:1px solid var(--border);
	margin-top:-3vh;
	border-radius:1vh;
	background-color: white;
	position:absolute;
	top:100%;
	right:0;
	left:0;
	display:none;
	max-height: 30vh;
	margin-bottom:3vh;
}

.container_search_select .item_list_select:first-child{
	border:none;
}

.show_search .container_search_select{
	display:flex;
}

.item_list_select{
	padding:2vh;
	color:var(--font);
	font-size:var(--size_3);
	display:flex;
	align-items:center;
	justify-content: flex-start;
	flex-shrink: 0;
	border-top:1px solid var(--border);
	align-self:stretch;
}

.container_selected .item_list_select{
  padding:0;
  border:0;
	flex:1;
	color:black;
}

.show_search{
  z-index:3;
}

.down_arrow{
	height:3vh;
	width:3vh;
	align-self: center;
	margin:-1vh;
}

.show_search .down_arrow{
	transform:rotate(180deg)
}






















.container_item_list_select_img .item_list_select_color{
	height:2vh;
	width:2vh;
	min-height:auto;
	min-width:auto;
	border-radius:50%;
	align-self:center;
	margin-left:1vh;
}

.name_item_select{
	flex:1;
}

.new_list .name_item_select, .create_list .name_item_select{
	color:var(--color);
}

.new_list .container_item_list_select_img{
	height:2vh;
	width:2vh;
	align-items: center;
	justify-content: center;
}

.show_search.container_field{
	z-index:3;
}


.container_selected .container_field_select{
	flex:1;
	border:1px solid var(--border);
	border-radius:1vh;
	position: relative;
	height:6vh;
}

.container_selected .container_field_select .item_list_select{
	flex:1;
	border:none;
	padding:1vh;
	color:black;
	padding-left:2vh;
}

.container_selected .remove_image{
	right:2vh;
	top:auto;
	background-color:var(--color);
	padding:1vh;
	border-radius:50%;
	margin-right:1vh;
}

.container_selected .remove_image img{
	height:1vh;
	width:1vh;
}


.container_selected{
	/*pointer-events:none;*/
}

.container_selected .remove_image{
	pointer-events: auto;
}





.container_field_select{
	flex:1;
	position:relative;
	align-items: center;
	background-color: white;
	border-radius: 1vh;
}

.container_field_select .down_arrow_select{
	height:1vh;
	position:absolute;
	right:3vh;
}

.disabled .container_field_select{
	background-color: var(--background_disabled);
	border-radius:1vh;
}



.item_list_select.skeleton img{
	height:2vh;
	width:2vh;
}






/*NEW*/
.container_multiple_selected{
	margin-top:2vh;
	flex-wrap:wrap;
}

.multiple_selected{
	border-radius:3vh;
	padding:0.5vh 1vw;
	margin-right:1vw;
	font-size:var(--size_3);
	max-width: 16vw;
	margin-bottom:2vh;
	flex-direction: column;
	border:1px solid var(--color);
	align-items:center;
	position:relative;
}

.inner_selected{
	flex-direction: row;
	color:var(--color);
	align-self:stretch;
}

.label_multiple_selected{
	flex: 1;
	min-width:0;
	align-items: center;
}

.label_multiple_selected span{
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.container_order{
	margin-left:1vw;
}

.item_selected{
	color:var(--color);
}

.container_item_list_select_img{
	height:4vh;
	width:4vh;
	overflow:hidden;
	margin-right:2vh;
	align-items: center;
	justify-content: center;
}

.container_item_list_select_img .item_list_select_img{
	max-height:100%;
	max-width:100%;
	object-fit: contain;
}

.item_create{
	color:var(--color);
}



.multiple_selected.multiple_selected_description{
	padding:0;
	border-radius:0;
	margin-left:1px;
	margin-right:1px;
	margin-top:5vh;
}

.multiple_selected.multiple_selected_description .inner_selected{
	border:1px solid var(--color);
	border-radius:3vh;
	background: white;
	transform:translateY(-50%);
	margin:-1px;
}

.multiple_selected_description{
	padding-bottom:0;
	width:100%;
	max-width:none;
}

.multiple_selected_description .inner_selected{
	padding:1vh;
}

.multiple_item_select{
	height:2vh;
	width:2vh;
	border-radius:50%;
	border:1px solid var(--border);
	margin-left:2vh;
}

.item_selected .multiple_item_select{
	background-color: var(--color);
}




/* filter */
.filter{
	margin:2vh 0;
	padding:0;
}

.filter .container_input_select{
	border:none;
	border:1px solid var(--border);
	border-right:none;
	border-radius:0;
}

.filter:last-child .container_input_select{
	border:1px solid var(--border);
}

.filter .inner_list{
	border:1px solid var(--color);
}

.filter .item_list_select{
	flex-direction:row-reverse;
}

.filter .multiple_item_select{
	margin-left:0;
	margin-right:1vw;
	border-radius:0;
	height:1.5vh;
	width:1.5vh;
}

.counter{
	background-color: var(--color);
	border-radius:50%;
	height:2vh;
	width:2vh;
	font-size:1.2vh;
	color:white;
	align-items: center;
	justify-content: center;
	font-weight:bold
}




.disabled{
	pointer-events:none;
}




.item_missing .container_input_select{
	border-color:var(--red);
}


@media only screen and (max-width: 600px) {
	.item_list_select:last-child{
		border-bottom:1px solid var(--border);
	}

	.item_list_select{
		padding:3vh 5vw;
	}

	.multiple_selected{
		max-width:80vw;
		padding-left:3vw;
		padding-right:2vw;
	}

	.filter{
		margin:0;
	}

	.filter .container_input_select{
		padding:3vh 5vw;
		border-top:none !important;
	}

	.filter .down_arrow{
		transform:rotate(-90deg)
	}

	.bannerButtons{
    padding:2vh 4vw;
    border-top:1px solid var(--border);
    justify-content: flex-end;
  }
}
