.container_carrousel{
  flex:1;
  align-self:stretch;
  flex-direction:row;
  width:50%;
  position:relative;
}

.container_item_carrousel{
  height:100%;
  width:50vw;
  overflow:hidden;
  position: absolute;
  right:-50vw;
  -webkit-transition: transform 1s ease;
  transition: transform 1s ease;
}



.container_cabs{
  position:absolute;
  bottom:10vh;
  z-index:3;
  align-self: center;
  width:50vw;
  left:0;
  right:0;
  align-items: center;
}


.outer_cab{
  align-items: center;
  justify-content: center;
  position: absolute;
  width:50vw;
  right:-50vw;
  -webkit-transition: transform 1s ease;
  transition: transform 1s ease;
  bottom:6vh;
}

.container_cab{
  align-items:center;
  background-color: white;
  border-radius:1vh;
  box-shadow:var(--shadow);
  height:8vh;
  justify-content: center;
  max-width:20vw;
}

.container_logo{
  width:5vw;
  height:100%;
  padding:2vh;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.container_logo img{
  max-width:100%;
  max-height:100%;
  object-fit: contain;
}

.container_name{
  font-size:var(--size_2);
  margin-right:2vw;
}


.back, .forward{
  position:absolute;
  cursor:pointer;
  z-index:3
}

.back{
  left:10vw;
}

.forward{
  right:10vw;
}


@media only screen and (max-width: 600px) {
  .container_carrousel{
    width:100%;
  }
  
  .container_item_carrousel{
    width:100vw;
    right:-100vw;
  }

  .container_cabs{
    width:100vw;
    bottom:20vh;
  }

  .container_cab{
    max-width:60vw;
  }

  .outer_cab{
    right:-100vw;
    width:100vw;
    bottom:16vh;
  }

  .container_logo{
    width:20vw;
  }

  .container_name{
    margin-right:4vw;
  }
  
}