footer{
	background-color: var(--dark_grey);
	align-self:stretch;
	flex-shrink:0;
	flex-direction:row;
	padding-top:10vh;
	display:flex;
	align-items:flex-start;
	padding-bottom:8vh;
	box-sizing:border-box;
	padding-right:2vw;
}

#image_footer{
	height:8vh;
}

.column_footer{
	flex-direction: column;
	margin-right:5vw;
	min-width:12vw;
}

.title_footer{
	color:var(--sub_color);
	font-size:2.5vh;
	font-weight:bold;
	margin-bottom:3vh;
}

.item_footer{
	margin-bottom:2vh;
	font-weight:500;
	font-size:2vh;
	color:white;
}

.item_footer span{
	color:white;
	cursor:pointer;
}

.item_footer span:hover{
	opacity:0.7;
}

.column_footer:last-child{
	flex:3;
}

.column_footer_image{
	width:25vw;
	align-items: center;
	justify-content: center;
}






#container_subscribe_footer{
	position:relative;
}


#container_form{
	flex:1;
	z-index:1;
	border-radius:1vh;
	overflow:hidden;
}

#container_form input{
	flex:1;
	padding:1.5vh 1vw;
	border:none;
}

#container_form .button_subscribe{
	background-color: var(--sub_color);
	color:white;
	align-items:center;
	justify-content: center;
	padding:1vh 1.5vw;
	font-size:1.8vh;
	font-weight:600;
	cursor:pointer;
	margin-left:-2vh;
}

#container_form .button_subscribe:hover{
	opacity:0.7;
}

#confirm_form{
	opacity:0;
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-color: var(--sub_color);
	color:white;
	-webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;
    z-index:0;
    align-items:center;
    justify-content: center;
    font-size:1.8vh;
	font-weight:600;
}

.confirm #confirm_form{
	opacity:1;
	z-index:2;
}

@media only screen and (max-width: 600px) {
	footer{
		flex-direction:column;
		padding:5vh;
		padding-bottom:5vh;
	}

	.column_footer_image{
		align-self:center;
		margin-bottom: 2vh;
	}

	.column_footer{
		margin-top:3vh;
		margin-right:0;
	}

	.item_footer{
		margin-right:6vw;
	}

	#container_form input{
		padding-left:3vw;
		margin:0;
	}

	#confirm_form{
		border-radius:1vh;
	}

	#image_footer{
		height:5vh;
	}

}