.container{
  position:fixed;
  bottom: 5vh;
  right: 5vh;
  background:var(--color);
  width:200px;
  z-index:5;
  flex-direction:column;
  padding: 30px;
}

.image{
  margin: 30px 0;
  align-items:center;
  justify-content: center;
}

.title{
  color:white;
  font-size:var(--size_2);
  text-align:center;
  font-weight:500;
}

.icon{
  position:absolute;
  top:10px;
  right:10px;
  cursor:pointer;
}