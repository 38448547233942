.title_section{
  padding:2vh;
  font-size:var(--size_3);
  color:var(--font);
  font-weight:500;
  margin-top:2vh;
}

.outer_card{
  align-self:stretch;
  flex-direction: column;
}

