.container_user{
  padding:2vh 1vw;
  align-items:center;
}


/* infos user*/
.infos_user{
  flex:1;
  padding:0 0.5vw;
  box-sizing:border-box;
}

.container_image_user{
  position:relative
}

.image_user{
  height:6vh;
  width:6vh;
  border-radius: 50%;
  overflow:hidden;
  align-items:center;
  justify-content: center;
}

.name_user{
  flex-direction: column;
  margin-left:1vw;
  justify-content:center;
}

.name_user .main_info{
  margin-bottom:0.5vh;
  font-size:var(--size_2);
}

.main_info{
  font-size:var(--size_3);
}

.sub_info{
  font-size:var(--size_3);
  color:var(--font);
}

.schools_user, .cabs_user{
  flex-direction:column;
  flex:1;
  justify-content: flex-start;
  overflow:hidden;
  padding:0 0.5vw;
  box-sizing:border-box;
}

.school, .cab{
  flex-direction:row;
  align-items:flex-end;
  white-space:nowrap;
  overflow:hidden;
  width:100%;
}

.school .sub_info, .cab .sub_info{
  margin-left:0.5vw;
  text-overflow:ellipsis;
  flex:1;
  overflow:hidden;
}

.lign_info{
  white-space:nowrap;
  overflow:hidden;
  width:100%;
  text-overflow:ellipsis;
}






.container_user.unavailable{
  background-color: var(--grey_background);
}


.badge{
  position:absolute;
  height:2vh;
  width:2vh;
  border-radius:50%;
  overflow:hidden;
  box-sizing: border-box;
}

.badge_senior{
  bottom:0;
  right:-0.5vh;
  font-size:1.5vh;
  background: #367CFF;
  color:white;
  font-weight:500;
  align-items:center;
  justify-content: center;
  line-height: 1.5vh;
}

.badge_junior{
  background:#0CBC8B;
}

.badge_unavailable{
  background-color: var(--grey);
  top:0;
  right:-0.5vh;
  z-index:2;
}

.badge_complete{
  background:var(--color);
  right:-0.5vh;
  padding:0.5vh;
}