div {
  display: flex;
}

body,
html,
#root {
  height: 100%;
  width: 100%;
  background-color: white;
}

video,
input,
textarea,
a {
  outline: none;
  text-decoration: none;
  outline: none;
}

a {
  display: flex;
  color: black;
}

p a {
  display: inline;
  color: var(--color);
  text-decoration: underline;
}

:root {
  --size_1: 2.4vh;
  --size_2: 2.1vh;
  --size_3: 1.8vh;
  --size_4: 1.6vh;

  --margins: 10vh 20vw;

  --background: #fbfbfb;
  --grey_background: #e5e5e5;
  --background_list: #f4f4f4;
  --grey_border: #dddddd;
  --font: #777676;
  --color: #8278f9;
  --purple: #8278f9;
  --sub_color: #8278f9;
  --dark_color: #5447f3;
  --red: #fa8080;
  --dark_red: #f84f4f;
  --light_color: rgba(130, 120, 249, 0.25);
  --light_gold: rgba(242, 201, 76, 0.15);
  --border: #dddddd;
  --dark_grey: #3f3f3f;
  --grey: #cccccc;
  --light_grey: #f2f2f2;
  --gold: #d1b000;
  --green: #0cbc8b;
  --dark_green: #0aa479;
  --orange: rgba(233, 136, 98, 0.85);
  --dark_orange: rgba(233, 136, 98, 1);

  --shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07), 0 5px 20px 0 rgba(0, 0, 0, 0.06),
    0 8px 40px 0 rgba(0, 0, 0, 0.04);
  --shadow_list: 0 2px 1px 0 rgba(0, 0, 0, 0.07),
    0 5px 20px 0 rgba(0, 0, 0, 0.06);
  --shadow_3: 0 2px 1px 0 rgba(0, 0, 0, 0.07), 0 3px 12px 0 rgba(0, 0, 0, 0.06),
    0 5px 25px 0 rgba(0, 0, 0, 0.04);

  --details_top_font: #5daad6;
  --details_top: rgba(120, 203, 249, 0.25);
  --details_high_font: #5a825a;
  --details_high: rgba(123, 249, 120, 0.25);
  --details_middle_font: #897646;
  --details_middle: rgba(249, 213, 120, 0.25);
  --details_low_font: #713a3a;
  --details_low: rgba(249, 120, 120, 0.25);
}

.color {
  color: var(--color);
}

#main_container {
  display: flex;
  flex: 1;
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
}

.card {
  background-color: white;
  border-radius: 0.5vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 1vh;
}

.container {
  flex: 1;
  align-self: stretch;
  flex-direction: column;
  overflow: hidden;
}

.container_main_site {
  background-color: var(--background);
}

.shrinked_container {
  width: 60%;
  align-self: center;
}

.active g,
.active path {
  fill: var(--color);
  fill-opacity: 1;
}

highlight {
  font-size: 2.5vh;
  border-left: 4px solid var(--color);
  padding-left: 3vh;
  margin: 2vh;
  margin-left: 0;
  font-weight: 600;
  display: flex;
}

.round_image img {
  height: 3vh;
  width: 3vh;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1vw;
  overflow: hidden;
}

.old_list {
  align-self: stretch;
}

.react-pdf__Document {
  flex: 1;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

.react-pdf__Page {
  align-items: center;
  justify-content: center;
  margin-bottom: 1vh;
  flex-shrink: 0;
}

.react-pdf__Page__annotations {
  display: none;
}

.ReactModalPortal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  padding: 0 !important;
  z-index: 100;
  inset: 0 !important;
  border: none !important;
  background: transparent !important;
  align-items: center;
  justify-content: center;
  border-radius: 0 !important;
}

.ReactModal__Overlay {
  z-index: 1000;
  background-color: transparent !important;
}

.image_cover {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.image_contain {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.title {
  font-weight: 500;
  font-size: var(--size_1);
  margin-bottom: 3vh;
}

.description {
  font-size: var(--size_2);
  color: var(--font);
  margin-bottom: 3vh;
}

/* lexique */

.intext_lexique {
  position: relative;
  cursor: pointer;
  text-decoration: underline dotted var(--font);
  font-weight: 500;
}

.intext_lexique a {
  color: var(--purple);
  display: inline;
}

.intext_lexique:hover .intext_popup_definition_lexique {
  display: flex;
}

.intext_popup_definition_lexique {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: var(--shadow);
  left: 0;
  border-radius: 1vh;
  min-width: 20vw;
  padding: 2vh;
  flex-direction: column;
  z-index: 2;
}

.intext_title_definition_lexique {
  color: var(--purple);
  font-size: 2.2vh;
  margin-bottom: 2vh;
  font-weight: 600;
}

.intext_definition_lexique {
  color: var(--font);
  font-size: 1.8vh;
  line-height: 2vh;
}

@media only screen and (min-width: 600px) {
  .hover_color:hover {
    color: var(--dark_color);
    border-color: var(--dark_color);
    cursor: pointer;
  }

  .hover_color_font:hover {
    color: var(--dark_color);
    cursor: pointer;
  }

  .hover_color:hover span {
    color: var(--dark_color);
  }

  .hover_card:hover {
    cursor: pointer;
  }

  .hover_color:hover path,
  .hover_color:hover g {
    fill: var(--dark_color);
    color: var(--dark_color);
    opacity: 1;
  }

  .hover_background_color:hover {
    background-color: var(--dark_color);
    cursor: pointer;
    color: white;
  }

  .hover_background_orange:hover {
    background-color: rgba(233, 136, 98, 1);
    cursor: pointer;
  }

  .hover_background_red:hover {
    background-color: var(--dark_red);
    cursor: pointer;
  }

  .hover_red {
    cursor: pointer;
  }

  .hover_red_font:hover {
    color: var(--dark_red);
    cursor: pointer;
  }

  .hover_red:hover path,
  .hover_red:hover g {
    fill: var(--dark_red);
    color: var(--dark_red);
    opacity: 1;
  }

  .hover_background_grey:hover {
    background-color: var(--font);
    cursor: pointer;
  }

  .hover_grey:hover,
  .hover_grey:hover path,
  .hover_grey:hover g {
    fill: var(--font);
    color: var(--font);
    opacity: 1;
    cursor: pointer;
  }

  .hover_background_green:hover {
    background-color: var(--dark_green);
    cursor: pointer;
  }

  .hover {
    cursor: pointer;
  }
}

.error {
  color: var(--red);
  font-size: var(--size_3);
  align-self: flex-end;
  margin: 2vh 2vw;
  margin-top: 0;
}

.ql-video {
  min-height: 40vh;
}
