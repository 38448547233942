.container {
  min-height: 90vh;
  padding: 10vh 5vw;
  padding-bottom: 0;
  margin-bottom: 10vh;
  flex-direction: column;
  flex-shrink: 0;
}

.intro {
  flex-direction: row;
  align-items: stretch;
  margin: 3vh 0;
}

.logo {
  align-items: flex-start;
  justify-content: center;
}

.logo img {
  width: 80%;
}

.label_intro {
  font-size: var(--size_2);
  color: var(--font);
}

.label_details {
  font-size: var(--size_3);
  color: var(--font);
}

.label_details a {
  text-decoration: underline;
  cursor: pointer;
  color: var(--font);
  display: inline;
}

.container_simulator {
  flex: 1;
}

.form,
.logo {
  flex: 2;
  align-self: stretch;
}

.results,
.label_intro {
  flex: 3;
  flex-direction: column;
  margin-left: 5vw;
}

.results {
  justify-content: space-between;
  padding-bottom: 3vh;
}

.section {
  background: rgba(242, 242, 242, 1);
  flex-direction: column;
  align-items: stretch;
  padding: 3vh 3vw;
  position: relative;
}

.section_salaries {
  flex: 1;
  justify-content: space-between;
  padding-right: 12vw;
}

.section_results {
  flex-direction: column;
  margin: 1vh 0;
}

.items_salary {
  justify-content: space-between;
}

.title_section {
  font-size: var(--size_1);
  margin-bottom: 2vh;
}

.item_salary {
  flex-direction: column;
}

.title_item_salary {
  color: var(--font);
  font-size: var(--size_3);
  margin-bottom: 1vh;
}

.amount_item_salary {
  color: var(--color);
  font-size: var(--size_1);
  font-weight: 600;
  height: 20px;
  align-items: center;
}

.mention {
  color: var(--font);
  font-size: var(--size_3);
  align-self: flex-end;
  padding: 2vh 0;
}

.image_results {
  position: absolute;
  bottom: 2vh;
  right: 2vh;
  height: 20vh;
}

.intro_banner {
  flex-direction: row;
  align-items: center;
}

.illustration {
  height: 15vh;
  width: 10vw;
  align-items: center;
  justify-content: center;
  margin-right: 3vw;
}

.content {
  flex-direction: column;
}

.title_content {
  font-weight: 500;
  font-size: var(--size_2);
  margin-bottom: 1vh;
}

.subtitle_content {
  font-size: var(--size_2);
  margin-bottom: 2vh;
}

@media only screen and (max-width: 600px) {
  .container_simulator {
    flex-direction: column;
  }
  .container {
    padding: 5vh 5vw;
    min-height: auto;
  }
  .form,
  .results {
    flex: none;
  }
  .section {
    padding: 3vh 5vw;
  }
  .section_salaries {
    min-height: 60vh;
    padding-right: 3vw;
  }
  .image_results {
    position: relative;
    height: 10vh;
    margin-left: 3vw;
  }
  .footer_results {
    align-items: center;
  }
  .illustration {
    width: 20vw;
  }
  .title_content {
    text-align: center;
  }
  .item_salary {
    align-items: center;
    justify-content: flex-start;
  }
  .title_item_salary,
  .amount_item_salary {
    text-align: center;
  }
  .title_item_salary {
    height: 50px;
    align-items: center;
    justify-content: center;
  }
  .image_results {
    margin-right: 0;
    height: 80px;
    bottom: 0;
    right: 0;
  }
}
