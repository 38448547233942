#container_404{
	flex:1;
	align-self:stretch;
	align-items:center;
	justify-content: center;
}


#content_404{
	height:70%;
	align-items:center;
	justify-content: center;
	flex-direction:column;
}

#image_404{
	height:60%;
	position:absolute;
}


#image_error_404{
	height:30%;
	margin-bottom:5vh;
}

#text_404{
	color:var(--purple);
	flex-direction:column;
	max-width:50vw;
	flex:1;
	justify-content: space-evenly;
}

#text_404 div{
	font-weight:500;
	font-size:2.4vh;
}

@media only screen and (max-width: 600px) {

	#content_404{
		height:80%;
	}

	#image_error_404{
		height:auto;
		width:80%;
	}

	#text_404{
		max-width:none;
		margin-left:5vw;
		margin-right:5vw;
	}

	#text_404 div{
		font-weight:500;
		font-size:2vh;
	}
}