.min_article{
  width:48%;
  flex-direction:column;
  overflow:hidden;
  box-shadow: var(--shadow);
	margin:2vh 0;
}

.illustration_min_article{
  height:20vh;
  width:100%;
}

.details_min_article{
  flex-direction: column;
  padding:3vh 2vw;
  flex:1;
}

.title_min_article{
  font-size:var(--size_2);
  font-weight:bold;
  font-family:"Futura";
}

.container_abstract_min_article{
  flex:1;
}

.abstract_min_article{
  font-size:var(--size_2);
  margin:2vh 0;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date_min_article{
  font-size:var(--size_3);
  color:var(--font);
}

@media only screen and (max-width: 600px) {
  .min_article{
   	width:100%;
     margin:0;
  }
  .details_min_article{
		padding:3vh 5vw;
	}
}
