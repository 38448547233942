.container_cab{
  background: white;
  border-radius:1vh;
  margin:1vh;
  padding:2vh 1vw;
  align-items: center;
  position:relative;
}

.container_icon{
  width:5vw;
  height:3vh;
  align-items: center;
  justify-content: center;
  margin-right:2vw;
}

.container_icon img{
  max-height:100%;
  max-width:100%;
  object-fit: contain;
}

.container_name{
  font-size:var(--size_3);
  flex:1;
}

.details_cab{
  flex:1;
}

.container_favori{
  margin:0 1vw;
}

.container_favori img{
  height:2vh;
  width:2vh;
}

.favori path, .favori g{
  color:var(--gold);
  fill:var(--gold);
}

.banner_button{
  align-self:flex-end;
  margin-top:2vh;
}

@media only screen and (max-width: 600px) {
  .card_article_coaching{
    width:100%;
    height:auto;
  }
  .image_article_coaching{
    height:30vh;
  }
  .details_cab{
    flex-direction:column;
    flex:1;
    overflow:hidden;
    text-overflow: ellipsis;
  }
  .container_tag{
    color:var(--font);
    white-space:nowrap;
    width: 100%;
    overflow:hidden;
  }
  .container_tag div{
    text-overflow: ellipsis;
  }
  .container_icon{
    width:15vw;
    margin-right:6vw;
  }
  .container_cab{
    padding:2vh 2vw;
    align-items:center;
    width:96vw;
    overflow:hidden;
    margin:1vh 2vw !important;
    box-sizing: border-box;
  }
}
