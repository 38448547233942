.container_cab{
  padding:2vh 1vw;
  align-items:center;
}

.infos_cab{
  align-items:center;
  flex:2;
}

.image_cab{
  height:3vh;
  width:5vw;
  align-items: center;
  justify-content: center;
  margin-right:2vw;
}

.name_cab{
  font-size:var(--size_3);
}

.info{
  flex:1;
  font-size:var(--size_3);
}