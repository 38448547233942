.container_article {
  flex-direction: column;
  padding: 5vh 0;
  flex-shrink: 0;
}

.shrinked_article {
  margin: var(--margins) !important;
}

/* header */

.container_header {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 6vw;
  position: relative;
}

.container_back {
  left: 0;
  margin-right: 3vw;
  height: 3.2vh;
  align-items: center;
}

.container_back img {
  height: 2vh;
  width: 2vh;
}

.header {
  flex-direction: column;
}

.title {
  font-size: 2.8vh;
  font-weight: 500;
  margin-bottom: 2vh;
  line-height: 3.2vh;
}

.lastModif,
.time {
  color: var(--font);
  font-size: var(--size_3);
  align-items: center;
  margin-bottom: 1vh;
}

.time img {
  height: 2vh;
  width: 2vh;
  margin-right: 0.5vw;
}

/* content */

.content_article {
  flex-direction: column;
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}

.abstract {
  font-size: var(--size_1);
  font-weight: 500;
  margin: 0 2vw;
  margin-bottom: 2vh;
}

.image {
  height: 40vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2vw;
}

.image img {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.text {
  flex-direction: column;
  margin: 0 2vw;
  font-size: var(--size_2);
}

.container_image {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex: 1;
}

@media only screen and (max-width: 600px) {
  .container_article {
    margin: 0 !important;
  }
  .container_header {
    padding: 2vh 5vw;
  }
  .title,
  .container_back {
    line-height: 3.5vh;
  }
  .text,
  .abstract {
    padding: 2vh 0;
  }
}
