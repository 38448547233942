.homeuser{
  position:relative;
}

.tab{
  box-shadow:var(--shadow);
  flex-direction: row;
  background: white;
  position: -webkit-sticky;
	position: sticky;
  top: 7vh;
  z-index:3;
}

.banner_verif{
  height:5vh;
  background-color: var(--green);
  width:100%;
  position: absolute;
  left:0;
  right:0;
  bottom:0;
  color:white;
  align-items:center;
  font-size:var(--size_3);
  justify-content: center;
}

.resend_banner_verif{
  text-decoration: underline;
  margin-left:1vw;
}

.icon_banner_verif{
  height:2vh;
  width:2vh;
  margin-right:1vw;
}

.container_modal{
  justify-content: space-evenly;
  align-items: center;
  padding:6vh 5vw;
}

.label_modal{
  font-size:var(--size_3);
  flex-direction: column;
  margin:5vh 0;
}

.label_modal div{
  margin-bottom:1vh;
}