.button{
	border-radius:0.5vh;
	color:var(--color);
	border:1px solid var(--color);
	padding:1vh 2vh;
	font-size:var(--size_4);
	align-items: center;
	justify-content: center;
	text-align:center;
	cursor:pointer;
}

@media only screen and (min-width: 600px) {
	.button:hover{
		border-color:var(--dark_color);
		color:var(--dark_color);
	}
}

.full{	
	background-color:var(--color);
	color:white;
}

@media only screen and (min-width: 600px) {
	.full:hover{
		background-color: var(--dark_color);
		color:white;
	}
}

.disabled{
	pointer-events: none !important;
	opacity:0.5;
}



.cancel{
	background-color: #E0E0E0;
	color:var(--font);
	border-color: #E0E0E0;
}

@media only screen and (min-width: 600px) {
	.cancel:hover{
		background-color: #c7c7c7;
		border-color: #c7c7c7;
		color:var(--font);
	}
}



.delete{
	background-color:var(--red);
	color:white;
	border-color: var(--red);
}

@media only screen and (min-width: 600px) {
	.delete:hover{
		background-color:var(--dark_red);
		color:white;
		border-color: var(--dark_red);
	}
}




.validate{
	background-color:var(--green);
	color:white;
	border-color: var(--green);
}

@media only screen and (min-width: 600px) {
	.validate:hover{
		background-color:var(--dark_green);
		color:white;
		border-color: var(--dark_green);
	}
}



.warn{	
	background-color:var(--orange);
	color:white;
	border-color: var(--orange);
}

@media only screen and (min-width: 600px) {
	.warn:hover{	
		background-color:var(--dark_orange);
		color:white;
		border-color: var(--dark_orange);
	}
}


.white{	
	background-color:white;
	color:var(--color);
}

@media only screen and (min-width: 600px) {
	.white:hover{
		background-color: white;
	}
}



.button.disabled{
	opacity:0.3;
	cursor:default !important;
}

.icon_button{
	height:1.5vh;
	width:1.5vh;
	position:absolute;
	right:1.5vh;
}

.icon_button img{
	max-height:100%;
	max-width:100%;
	object-fit:contain;
}


.button.finished{
	background-color: #2E7CF6;
}

.button.loading{
	pointer-events:none;
}

.button img{
	margin-right:1vw;
	max-height:2vh;
	max-width:2vw;
}
