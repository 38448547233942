.content{
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	position:relative;
	flex:1;
	overflow:hidden;
}

.inner_content{
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	position:relative;
}

.item_content .min_article{
	width:27vw;
	align-self:stretch;
}

@media only screen and (max-width: 600px) {
	.content{
		flex-direction:column;
	}

	.item_content .min_article{
		width:85%;
	}
}