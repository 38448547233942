.container{
  flex-direction: column;
}

.text{
  font-size:var(--size_2);
  flex-direction:column;
	line-height:3vh;
	flex:5;
	margin-right:1vw;
}

.bloc_recruit{
	justify-content: flex-start;
}


/* TABLE */ 
.table{
	border:1px solid var(--border);
	border-radius:1vh;
	flex-direction: column;
	overflow: hidden;
	margin-top:4vh;
}

.header_table{
	flex-direction:row;
}

.item_header{
	flex:1;
	padding:2vh 0;
	color:var(--font);
	font-size:var(--size_2);
	border-bottom:1px solid var(--border);
	align-items: center;
	justify-content: center;
}

.item_header:last-child{
	border-left:1px solid var(--border)
}

/* CTA */
.container_banner{
	background-color: var(--light_grey);
	margin-top:2vh;
	padding:3vh 3vw;
	align-items: center;
	flex-direction:column;
	flex:1;
	margin-left:1vw;
	align-self:flex-start;
}

.intro_banner{
	flex-direction:row;
	align-items: center;
}

.illustration{
	height:20vh;
	width:10vw;
	align-items:center;
	justify-content: center;
	margin-right:3vw;
}

.content{
	flex-direction: column;
}

.title_content{
	font-weight:500;
	font-size:var(--size_2);
	margin-bottom:2vh;
}

.subtitle_content{
	font-size:var(--size_2);
	margin-bottom:2vh;
}

.container_offres{
	align-self:stretch;
	flex-direction: column;
}

.view_more{
	color:var(--color);
	font-size:var(--size_3);
	align-self: center;
	margin-top:2vh;
	text-decoration:underline;
}


.switch{
	align-self:center;
	border-radius:1vh;
	overflow:hidden;
	margin-top:4vh;
}

.item_switch{
	font-size:var(--size_2);
	padding:1.5vh 1vw;
	background-color: var(--light_grey);
	width:5vw;
	align-items:center;
	justify-content: center;
	cursor:pointer;
}

.item_switch.active{
	background-color: var(--color);
	color:white;
}


@media only screen and (max-width: 600px) {
	.item_switch{
		width:20vw;
	}
	.illustration{
		width:50vw;
		margin-right:5vw;
		margin-bottom: 5vh;
	}
	.container_banner, .content, .intro_banner{
		flex-direction: column;
		align-items:center;
	}
	.subtitle_content{
		text-align:center;
	}
	.bloc_recruit{
		flex-direction: column;
	}

	.container_banner{
		margin-left:0;
	}

	.text{
		margin-right:0;
	}
}