.containerPagination {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin-top: 40px;
  margin-bottom: 40px;
}

.containerPagination li {
  width: 30px;
  font-size: var(--size_3);
  cursor: pointer;
}

.containerPagination li a {
  color: var(--purple);
}

.containerPagination .active {
  font-weight: 700;
  text-decoration: underline;
}
