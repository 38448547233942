.containerSuccess{
  position:absolute;
  left:2vw;
  bottom:2vh;
  background: rgba(236, 238, 238, 1);
  padding:3vh 1.5vw;
  align-items:center;
}

.labelSuccess{
  font-size:var(--size_3);
}

.containerCloseSuccess{
  height:1.5vh;
  margin-left:1vw;
}