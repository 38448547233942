#container_cookies{
	position:absolute;
	z-index:10;
	bottom:2vh;
	left:2vh;
	border-radius:2vh;
	font-family:'Futura';
}

#inner_banner_cookies{
	background-color: white;
	border-radius:2vh;
	box-shadow:var(--shadow_3);
	padding:3vh;
	font-size:1.8vh;
	width:30vw;
	line-height: 2.3vh;
	align-items: center;
}

.label_banner_cookies .picto_cookie{
	padding-left:1vh;
	padding-right:1vh;
}

.button_banner_cookies{
	background-color: var(--purple);
	margin-left:2vh;
	padding:2vh;
	color:white;
	font-weight:bold;
	border-radius:2vh;
	box-shadow:var(--shadow_3);
}



#intro_cookies{
	display:none;
}

@media only screen and (max-width: 600px) {
	#inner_banner_cookies, .full_cookies #intro_cookies{
		display:none;
	}

	#intro_cookies, .full_cookies #inner_banner_cookies{
		display:flex;
	}

	#intro_cookies{
		background-color: white;
		border-radius:2vh;
		box-shadow:var(--shadow_3);
		padding:2vh;
		font-size:1.8vh;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		position:relative;
	}

	#container_cookies{
		bottom:auto;
		left:auto;
		right:1vh;
		top:7vh;
		width:30vw;
	}

	.full_cookies#container_cookies, .full_cookies #inner_banner_cookies{
		width:auto;
		left:1vh;
	}

	.label_intro_cookies{
		margin-top:1vh;
	}

	.show_more_cookies{
		color:var(--purple);
		margin-top:1vh;
	}

	.container_close_cookie{
		position:absolute;
		background-color: var(--purple);
		padding:1vh;
		border-radius:50%;
		top:-0.5vh;
		right:-0.5vh;
		box-shadow:var(--shadow_3);
	}

	.container_close_cookie img{
		height:1vh;
		width:1vh;
	}
}