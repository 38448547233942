.container_administratif{
  flex-direction: column;
  margin:var(--margins);
}

.title_administratif{
  font-size: 4vh;
  color:black;
  font-weight:600;
  padding-bottom:5vh;
  padding-top:4vh
}

@media only screen and (max-width: 600px) {
  .container_administratif{
    margin:2vh 5vw;
  }
}