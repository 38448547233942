/* header */

.container_header{
  padding:2vh 6vh;
  align-items: center;
  justify-content: space-between;
  border-bottom:1px solid var(--border);
}

.container_close{
  cursor:pointer;
}

.container_progress{
  align-items: center;
}

.progress_bar{
  background-color: var(--background);
  width:8vw;
  border-radius:3vh;
  margin-right:1vw;
  position:relative;
  height:2vh;
}

.inner_progress_bar{
  position: absolute;
  left:0;
  top:0;
  bottom:0;
  background-color: var(--color);
  border-radius:3vh;
}

.label_progress{
  font-size:var(--size_3);
  color:var(--font);
}

/* footer */

.banner_button{
  padding:2vh 6vh;
  align-items: center;
  justify-content: space-between;
  border-top:1px solid var(--border);
}

.onGoBack{
  color:var(--font);
  font-size:var(--size_3);
}

@media only screen and (max-width: 600px) {
	.container_progress{
    flex:1;
  }

  .label_progress{
    margin-left: 3vw;
    margin-right: 10vw;
  }

  .progress_bar{
    flex:1;
  }
}
