.header_school {
  align-items: center;
}

.round_color {
  height: 1.5vh;
  width: 1.5vh;
  border-radius: 50%;
  margin-right: 2vh;
}

.title_list_school {
  align-items: center;
  font-size: var(--size_2);
}

.figure_list_school {
  margin-left: 1vw;
  font-weight: 600;
}

.list_school {
  max-height: 500px;
  flex-direction: column;
  margin-bottom: 5vh;
  width: 100%;
  -webkit-transition: max-height 1s ease;
  transition: max-height 1s ease;
  overflow: hidden;
}

.logo_schools {
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 2vh;
  width: 100%;
  position: relative;
}

.item_school {
  align-items: center;
  justify-content: center;
  align-self: stretch;
  background-color: white;
  box-shadow: var(--shadow);
  margin: 1vh;
  width: calc(25% - 2vh);
  opacity: 1;
  position: static;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
}

.item_school.hidden {
  opacity: 0;
  position: absolute;
}

.view_more {
  position: absolute;
  color: var(--color);
  font-weight: 500;
  cursor: pointer;
  bottom: 0;
  height: 11vh;
  right: 0;
}

.logo_school {
  margin: 1vh;
  height: 9vh;
  width: 9vh;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .list_school {
    margin: 2vh 0;
  }
}
