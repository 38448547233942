.content{
	display:flex;
	flex-direction:column;
	flex-wrap:wrap;
	position:relative;
	margin-bottom:0;
}

.inner_content{
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	position:relative;
	width:100%
}