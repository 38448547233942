.container_field{
  flex-direction: row;
  align-items: center;
  padding:0;
  margin:0
}

.label_field{
  font-size:var(--size_2);
  flex:1;
}