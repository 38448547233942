/* linkedin */
.button_linkedin{
	background-color: #0e76a8;
	align-self:center;
	align-items:center;
	justify-content: center;
	padding:1vh;
	flex-direction:row;
	display:flex;
	flex-shrink:0;
}

.button_linkedin img{
	height:3vh;
	width:3vh;
}

.label_linkedin{
	color:white;
	font-size:2vh;
	font-weight:bold;
	margin-left:1vh;
}
