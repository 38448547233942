.container_banner_button{
	border-top:1px solid var(--border);
	padding:2vh 4vh;
	align-self:stretch;
	justify-content: space-between;
	align-items:center;
}

.delete_button{
	color:var(--red);
	font-size:var(--size_4)
}

.error{
	color:var(--red);
	font-size:var(--size_3);
	align-self:flex-end;
	margin:2vh 2vw;
	margin-top:0;
}