.container_comment{
  flex-direction:row;
  padding:1vh 1vw;
  align-items:center;
}

.comment{
  padding:2vh 1vw;
  font-size:var(--size_2);
}

.container_infos{
  justify-content: center;
  width:5vw;
  flex-direction: column;
  font-size:var(--size_3);
  align-items:center;
  text-align:center;
  margin-bottom: 1vh;
  padding:0 1vw;
  flex-shrink:0;
}

.date{
  font-size: var(--size_3);
  color:var(--font);
}

