.content {
  flex-direction: row;
  flex: 1;
}

.header {
  align-items: center;
  border-bottom: 1px solid var(--border);
}

.container_icon {
  padding: 2vh;
  height: 3vh;
  width: 3vh;
  margin-right: 1vw;
}

/* candidates */
.container_candidates {
  flex: 1;
  border-right: 1px solid var(--border);
  flex-direction: column;
}

.candidate {
  flex-direction: row;
  align-items: center;
  padding: 2vh 1vw;
  border-bottom: 1px solid var(--border);
}

.image_candidate {
  height: 4vh;
  width: 4vh;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--light_grey);
}

.name_candidate {
  font-size: var(--size_3);
  margin-left: 1vw;
  flex: 1;
}

.selected_candidate {
  background-color: var(--background);
}

.status_candidate {
  padding: 0.5vh 0.5vw;
  font-size: 1.6vh;
  font-weight: 500;
  border-radius: 4vh;
  align-items: center;
  color: white;
}

.new {
  background-color: var(--gold);
}

.unhandled {
  background-color: var(--color);
}

.tocontact {
  background-color: var(--orange);
}

.refused {
  background-color: var(--red);
}

.accepted {
  background-color: var(--green);
}

/* actions */
.container_actions {
  flex: 2;
  flex-direction: column;
}

.container_tab {
  flex-direction: row;
}

.tab {
  flex: 1;
  border-bottom: 1px solid var(--border);
  font-size: var(--size_3);
  height: 6vh;
  position: relative;
}

.tab .label_tab {
  border-bottom: 1px solid transparent;
  flex: 1;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}

.tab.active .label_tab {
  color: var(--color);
}

.tab.active {
  border-bottom: 1px solid var(--color);
}

.tab:hover .info_contact {
  display: flex;
}

.info_contact {
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 20;
  left: 50%;
  transform: translate(-50%, -100%);
  text-align: center;
  background-color: var(--color);
  color: white;
  padding: 1vh;
  border-radius: 1vh;
  font-size: var(--size_3);
  display: none;
}

.info_contact img {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 90%);
  left: 50%;
}

/* cv */
embed,
object {
  height: 100%;
  width: 100%;
}

/* footer */

.footer {
  align-items: center;
  border-top: 1px solid var(--border);
  height: 7vh;
}

.container_edit {
  align-items: center;
}

.container_edit .container_icon {
  margin-left: 1vw;
  margin-right: 0vw;
  padding-right: 0.5vw;
}

.label_edit {
  color: var(--color);
  font-size: var(--size_3);
}

.empty {
  align-self: stretch;
  color: var(--font);
  align-items: center;
  justify-content: center;
}

/* filter*/

.search {
  position: relative;
  align-items: center;
  padding: 0 1vh;
}

.icon_filter {
  height: 3vh;
  width: 3vh;
}
.icon_filter:nth-child(2) {
  margin-left: 10px;
}

.filter {
  background: white;
  z-index: 2;
}

.filter_status {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  flex-wrap: wrap;
  display: none;
}

.showFilters {
  display: flex;
}

.filter_status .status_candidate {
  margin: 1vh;
  cursor: pointer;
}

.icon_filter {
  height: 3vh;
  width: 3vh;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--grey);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon_trash{
  background-color: #fdcaca;
}

.icon_filter.active {
  background-color: var(--color);
}


.icon_trash.active, .icon_trash:hover{
  background-color: var(--red);
}

.default {
  background-color: var(--grey);
}

.status_intro {
  align-self: stretch;
  font-size: var(--size_3);
  color: var(--font);
  margin: 1vh 1vw;
  margin-top: 2vh;
}
