.container{
  flex-direction: row;
}

.text{
  font-size:var(--size_2);
  flex-direction:column;
  flex:1;
  margin-right:2vw;
  line-height:3vh;
}

.containerContext{
  flex:1;
  overflow:hidden;
  position:relative;
}

.bannerContextInfosClefs{
  position:absolute;
  background-color: var(--light_grey);
  top:46vh;
  right:1vh;
  height:18vh;
  width: calc(50% - 2vh);
  border-radius: 1vh;
  align-items:center;
  padding:1vh;
  font-size:var(--size_2);
  justify-content: space-evenly;
  box-sizing: border-box;
  text-align:center;
  flex-direction: column;
  z-index:2;
}

.labelContext{
  flex-direction: column;
  align-items:center;
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .container{
    flex-direction: column;
  }
  .text{
    flex:none;
    margin-right:0;
  }
}
