.item_mission{
  background-color: var(--light_grey);
  flex-direction: column;
  width:100%;
  padding:1vh 1vw;
  height:6vh;
  box-sizing:border-box;
  overflow:hidden;
  -webkit-transition: height 1s ease, box-shadow 0.5s ease;
  transition: height 1s ease, box-shadow 0.5s ease;
  margin:2vh;
  margin-left:5vh;
  cursor:pointer;
}

.item_mission:hover .header_mission{
  color:var(--dark_color);
  cursor:pointer;
}










.header_mission{
  align-items:center;
  margin-bottom:1vh;
}

.label_mission{
  flex:1;
  font-size:var(--size_2);
}

.arrow_mission{
  height:3vh;
  width:3vh;
  margin-left:1vw;
}

.icon_mission{
  height:4vh;
  width:4vh;
  margin-right:1vw;
  align-items:center;
}

.text_mission{
  flex-direction:column;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
  opacity:0;
  font-size:var(--size_2);
}

.show_mission .text_mission{
  opacity:1
}

.arrow_mission{
	-webkit-transition: transform 1s ease;
  transition: transform 1s ease;
}

.show_mission .arrow_mission{
  transform:rotate(180deg)
}


@media only screen and (max-width: 600px) {
  .item_mission{
    margin:2vh 0;
  }

  .header_mission{
    padding:0 2vw;
  }

  .icon_mission{
    margin-right:2vw;
  }
}