.container_banner {
  flex-direction: column;
  align-items: center;
}

.container_step {
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.container_image {
  height: 20vh;
  width: 10vw;
}

.container_image img {
  min-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.container_description {
  padding: 5vw 5vh;
  align-items: flex-start;
}

.container_index {
  background-color: var(--color);
  height: 3vh;
  width: 3vh;
  flex-shrink: 0;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: var(--size_3);
  font-weight: 500;
  margin-right: 1vw;
}

.container_label {
  font-size: var(--size_2);
}

@media only screen and (max-width: 600px) {
  .steps {
    flex-direction: column;
  }

  .container_step {
    margin-bottom: 5vh;
  }

  .container_image {
    width: 30vw;
  }

  .container_description {
    padding: 2vh 2vw;
    align-items: center;
  }

  .container_index {
    margin-right: 5vw;
  }
}
