.banner_button{
  border-top:1px solid var(--border);
  padding:2vh 2vw;
  justify-content: flex-end;
}

.content{
  font-size:var(--size_2);
  margin:4vh 2vw;
  padding-bottom:10vh;
  flex-direction:column;
}

.subtitle_popup{
  font-size:var(--size_2);
  color:var(--font);
  margin:2vh 0;
}