.container_actuality {
  flex-direction: column;
}

.section {
  width: 100%;
  align-items: flex-start;
}

.section_article {
  flex-direction: column;
}

.twitter_feed {
  width: 50%;
  padding-right: 2vw;
  box-sizing: border-box;
  background: var(--light_grey);
  padding: 3vh;
  flex-direction: column;
  height: calc(500px+ 6vh);
}

.twitter_feed div {
  width: 100%;
}

.intro_twitter {
  flex-direction: row;
  align-items: center;
  height: 50px;
  flex-shrink: 0;
}

.icon_twitter {
  height: 2vh;
  width: 2vh !important;
  margin-right: 1vw;
}

.label_twitter {
  flex: 1;
  width: auto !important;
  font-size: var(--size_2);
}

@media only screen and (max-width: 600px) {
  .container_actuality,
  .twitter_feed {
    width: 100%;
  }
  .section {
    flex-direction: column;
    align-items: center;
  }
}
