.content{
  flex-direction: column;
  padding:4vh 2vw;
  max-width:40vw;
  box-sizing: border-box;
  margin-bottom:10vh;
}

.title{
  font-weight:500;
  font-size:var(--size_2);
}

.sub_title{
  font-size:var(--size_3);
  color:var(--font);
  margin-top:2vh;
}

.banner_buttons{
  border-top:1px solid var(--border);
  padding:2vh 2vw;
  align-items: center;
}

.confirm{
  flex:1;
  align-items: flex-start;
  justify-content: flex-start;
}

.checkbox{
  height:2vh;
  width:2vh;
  border:1px solid var(--border);
  border-radius:0.5vh;
  margin-right:1vw;
}

.isChecked{
  background-color: var(--color);
  border-color:var(--color)
}

.label_checkbox{
  font-size:var(--size_3);
  color:var(--font);
  line-height:2.5vh;
}

