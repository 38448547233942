.banner{
  background-color: #F6F6F6;
  padding:4vh 2vw;
  margin:5vh 0;
  position:relative;
  flex-direction:column;
}

.content_context{
  flex-direction: row;
/*   align-items:stretch; */
  align-items:center
}

.image{
  height:15vh;
  width:15vw;
  margin-right:4vw;
  align-items:center;
  justify-content: center;
  align-self:center;
}

.content{
  flex-direction: column;
  justify-content: space-evenly;
}

.title{
  font-size:var(--size_3);
  font-weight:500;
}

.subtitle{
  font-size:var(--size_3);
  margin:1vh 0;
}

.close{
  position:absolute;
  top:2vh;
  right:2vh;
  height:2vh;
  width:2vh;
}

@media only screen and (max-width: 600px) {
  .banner{
    padding:2vh 5vw;
  }
  .image{
    width:30vw;
  }
}