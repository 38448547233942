.container_list_users{
  flex:1;
}

.extraBar{
  margin-left:0.5vw;
}

.extraBarOption{
  border:1px solid var(--font);
  color:var(--font);
  padding:0 1vw;
  margin:0 0.5vw;
  border-radius:4vh;
  align-items:center;
  font-size:var(--size_3);
}

.extraBarOption:last-child{
  margin-right:0;
}

.extraBarOption.active{
  color:var(--color);
  border-color:var(--color);
}

.icon_filter{
  height:3vh;
  width:3vh;
  border-radius:50%;
  overflow:hidden;
  background-color: var(--grey);
  align-items:center;
  justify-content: center;
}

.icon_filter.active{
  background-color: var(--color);
}