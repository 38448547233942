.container_home {
  flex-direction: column;
  align-self: stretch;
  flex-shrink: 0;
}

.intro_main_page {
  height: 90vh;
  width: 100%;
  align-items: center;
  position: relative;
  overflow: hidden;
}

/* card  */

/* .card_main_page{
	position:absolute;
	flex-direction: column;
	height:80%;
	width:30vw;
	left:10vw;
	margin-top:5vh;
	z-index: 3;
} */

.card_main_page {
  align-items: center;
  justify-content: center;
  flex: 1;
  align-self: stretch;
  z-index: 4;
  position: relative;
}

.background {
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.content_card {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 12vh;
  padding-bottom: 20vh;
  margin-top: 8vh;
}

.container_logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.container_logo img {
  max-width: 100%;
  object-fit: contain;
}

.container_presentation {
  flex-direction: column;
}

.item_presentation {
  align-items: center;
  font-size: 2.5vh;
  line-height: 3vh;
  margin: 1.5vh 0;
}

.label_presentation {
  font-size: 3vh;
}

.item_presentation img {
  height: 3vh;
  width: 3vh;
  margin-right: 1vw;
}

.container_infos {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 2vh;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.item_info {
  flex-direction: column;
  align-items: center;
  width: 40%;
}

.item_info img {
  height: 4vh;
  width: 4vh;
}

.title_info {
  font-size: var(--size_1);
  font-weight: 500;
  margin: 1vh 0;
}

.description_info {
  font-size: var(--size_1);
  color: var(--font);
  text-align: center;
}

/* sections */
.section {
  padding: 10vh 10vw;
  flex-direction: column;
}

.section_offres {
  background-color: white;
}

.title_section {
  align-items: flex-end;
}

.label_title_section {
  font-size: 3vh;
  font-weight: 600;
  flex: 1;
  font-family: "Futura";
}

.see_more {
  color: var(--color);
  font-size: var(--size_2);
  align-items: center;
  font-weight: 500;
}

.see_more img {
  height: 2vh;
  width: 2vh;
  margin-left: 0.5vw;
}

.content_section {
  margin-top: 10vh;
}

.content_section_list {
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
  .section:last-child {
    padding-bottom: 15vh;
  }

  .intro_main_page {
    height: 80vh;
  }
  .card_main_page {
    position: absolute;
    left: 10vw;
    right: 10vw;
    top: 50%;
    transform: translateY(-70%);
    border-radius: 1vh;
    overflow: hidden;
  }

  .label_presentation {
    font-size: var(--size_1);
  }

  .background {
    opacity: 0.8;
  }

  .content_card {
    align-items: center;
    padding: 5vh 5vw;
    margin-top: 0;
  }

  .container_presentation {
    align-self: stretch;
  }

  .container_logo {
    position: relative;
    margin: 2vh 02vw;
    height: 5vh;
    width: 100%;
  }

  .section {
    padding: 5vh 5vw;
  }

  .title_section {
    flex-direction: column;
    align-items: center;
  }

  .label_title_section {
    text-align: center;
    margin-bottom: 0vh;
  }

  .see_more {
    margin-top: 5vh;
  }
}
