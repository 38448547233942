.container{
  flex-direction: row;
  align-items:flex-start;
  width:100%;
}

.text{
  font-size:var(--size_2);
  flex-direction:column;
  line-height:3vh;
  flex:1;
}

.content{
  flex:1;
  flex-direction: column;
  overflow:hidden;
  margin-left:1.5vw;
}

.linkedin{
  background-color: white;
  box-shadow: var(--shadow_3);
  margin:2vh;
  width:22vw;
  align-self:center;
  border-radius:1vh;
  cursor:pointer;
}

.image{
  height:12vh;
  width:12vh;
  border-radius:1vh 0 0 1vh;
  overflow:hidden;
}

.details{
  flex-direction: column;
  align-self:stretch;
  justify-content: space-evenly;
  margin-left:1.5vw;
}

.icon{
  height:2vh;
  width:2vh;
}

.name{
  font-size:var(--size_2);
}

.label{
  font-size: var(--size_3);
  color:var(--font);
}

@media only screen and (max-width: 600px) {
  .container{
    flex-direction:column;
  }
  .text, .content{
    flex:none;
    width:100%;
    margin:0;
  }

  .linkedin{
    width:80%
  }
}