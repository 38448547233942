.buddy_score{
	align-items:center;
	justify-content: center;
	background: var(--light_color);
	padding:0.5vh;
	border-radius:4vh;
	color:var(--color);
	font-weight:bold;
	font-size:var(--size_4);
	align-self:flex-start;
	position:relative;
}

.container_logo_buddy_score{
	height:2vh;
	width:2vh;
	align-items: center;
	justify-content: center;
	background-color: var(--color);
	border-radius:50%;
}

.container_logo_buddy_score img{
	height:1.2vh;
	width:1.2vh;
}

.score_buddy{
	flex:1;
	align-items:center;
	justify-content: center;
	margin:0 0.5vw;
}


.container_details{
  position:absolute;
  right:0;
  top:3vh;
  z-index:2;
  flex-direction:row;
  align-items:stretch;
  padding:2vh 0.5vw;
  width:20vw;
  display:none;
}

.item_details{
  flex-direction:column;
  align-items:center;
	flex:1;
}

.label_details{
  padding:3vh 0.5vw;
  font-size:var(--size_3);
  text-align:center;
	color:var(--font);
	font-weight:normal;
	align-items: center;
	justify-content: center;
	flex:1;
}

.label_details div{
	display:inline;
}

.label_details span{
	font-weight:600;

}

.score_details{
  padding:1vh 1vw;
  border-radius:3vh;
}

.top_score{
	color:var(--details_top_font);
	background-color: var(--details_top);
}

.high_score{
  color:var(--details_high_font);
	background-color: var(--details_high);
}

.middle_score{
  color:var(--details_middle_font);
	background-color: var(--details_middle);
}

.low_score{
  color:var(--details_low_font);
	background-color: var(--details_low);
}

.buddy_score:hover .container_details{
	display:flex
}

.image_details{
	height:3vh;
	width:3vh;
}