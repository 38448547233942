.header{
  flex-direction:column;
  align-items: center;
  justify-content: center;
  padding:5vh 2vw;
}

.image{
  height:8vh;
  width:10vw;
  align-items: center;
  justify-content: center;
  margin-bottom:2vh;
}

.name{
  font-size:var(--size_2);
  font-weight:500;
}