.header_utilisateur{
  flex-direction:column;
  margin:2vh 2vw;
}

.intro_header{
	justify-content: space-between;
	align-items:center;
}

.contract{
	font-size:var(--size_3);
	color:var(--font);
}

.profile{
  align-items:center;
  justify-content: center;
  flex-direction:column;
	margin-bottom:1vh;
}

.image_user{
  height:6vh;
  width:6vh;
  border-radius:50%;
  overflow:hidden;
  align-items:center;
  justify-content: center;
  margin-bottom:1vh;
}

.name_profile{
	font-size:var(--size_2);
	align-items: center;
	position:relative;
	margin-top:1vh;
	margin-bottom:1vh;
}

.input_name{
  border:none;
  background:none;
}

.profile input{
	border:none;
	background-color: white;
}

.profile .button{
	border:none;
	border-radius:1vh;
	padding:0.5vh 1vh;
	color:white;
	display:none;
	margin-right:1vh;
	margin-left:1vh;
}


.profile_edit input{
	background-color:#efefef;
	padding:0.5vh 1vh;
	border-radius:1vh;
}

.profile_edit .button{
	display:flex;
}

.profile_edit .container_edit_profile{
	display:none;
}

.profile_edit .input_name input{
	margin-right:1vh;
}

.container_edit_profile{
	height:2vh;
	width:2vh;
	margin-left:2vh;
	position:absolute;
	right:-3vh;
}

.item_profile{
	margin-left:1vh;
	margin-right:1vh;
	align-items: center;
}

.item_profile img{
	margin-right:1vh;
}


.button_save{
	background-color:var(--purple);
}

.button_delete{
	background-color:var(--red);
}