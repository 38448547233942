.container_input{
	display:flex;
	flex:1;
	flex-direction:row;
	align-items:center;
}

.container_input input, .container_input textarea, .container_selected{
	border-radius:1vh;
	border:1px solid var(--grey_border);
	background-color: white;
	flex:1;
	padding:1.5vh 2vh;
}

.container_input input::placeholder, .select_disabled{
	color:var(--font);
	font-size:1.6vh;
	opacity:1;
}

.select_disabled{
	cursor:pointer;
	flex:1;
}







.container_select{
	display:flex;
	flex-direction:column;
	position:relative;
}

.container_select .container_input{
	z-index:2;
}

.container_select.show_search .container_input{
	z-index:4;
}

.container_search_select{
	border:1px solid var(--grey_border);
	margin-top:-3vh;
	/*padding-top:2vh;*/
	border-radius:1vh;
	background-color: white;
	position:absolute;
	top:100%;
	right:0;
	left:0;
	display:none;
	max-height: 30vh;
}

.container_search_select .item_list_select:first-child{
	margin-top:1vh;
}

.show_search .container_search_select{
	display:flex;
	z-index:3;
}

.item_list_select{
	padding:2vh;
	color:var(--font);
	font-size:1.8vh;
	display:flex;
	align-items:center;
	justify-content: center;
	cursor:pointer;
	flex-shrink: 0;
	font-family:'Futura';
}

.item_list_select:hover{
	opacity:0.7;
}


.container_name_item_search{
	flex:1;
}



.inner_item_select{
	flex:1;
	align-items:center;
}





/* selected */

.container_input_select{
	height:100%;
}

.container_selected{
	align-items:center;
	padding:0;
	align-self: stretch;
}

.container_selected .item_list_select{
	flex:1;
	border:none !important;
}


.icon_select{
	height:2vh;
	width:2vh;
	cursor:pointer;
	padding:1vh;
}

.icon_select:hover{
	opacity:0.7;
}


