.offer{
  flex-direction: column;
}

.header_offer{
  align-items: center;
}

.content_offer{
  padding:2vh 2vw;
  flex-direction:column;
}

.container_icon{
  height:3vh;
  width:5vw;
  align-items:center;
  justify-content: center;
  margin-right:2vw;
}

.container_name{
  flex-direction: column;
  flex:1
}

.date{
  align-self: flex-start;
  font-size:var(--size_3);
  color:var(--font);
}

.name{
  font-size:var(--size_2);
}

.sub_title{
  color:var(--font);
  font-size:var(--size_3);
  margin-top:1vh;
}

.presentation{
  margin:4vh 0;
  font-size:var(--size_2)
}




.banner_button{
  border-top:1px solid var(--border);
  padding:2vh 2vw;
  justify-content: flex-end;
}

.content{
  font-size:var(--size_2);
  margin:4vh 2vw;
  flex-direction:column;
}

.content div{
  margin-bottom:2vh;
}

.subtitle_popup{
  font-size:var(--size_2);
  color:var(--font);
  margin:2vh 0;
}

.containerIllustration{
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-bottom:4vh;
}

@media only screen and (max-width: 600px) {

  .offer{
    padding:2vh 2vw;
  }
  .container_icon{
    width:15vw;
    margin-right:3vw;
  }
  .content{
    flex:1;
    padding:2vh 2vw;
  }
}