.container_activity{
  padding:2vh 1vw;
  align-items: center;
}

.date{
  font-size:var(--size_3);
  color:var(--font);
  width:6vw;
}

.label{
  margin-left:1vw;
  font-size:var(--size_3)
}