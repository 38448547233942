.container_tab{
  margin:2vh 0;
  overflow:hidden;
  flex-shrink:0;
}

.item_tab{
  display:flex;
  flex:1;
  align-items: center;
  justify-content: center;
}

.item_tab span{
  font-size:var(--size_3);
  color:var(--font);
}

.item_tab svg{
  width:var(--size_2);
  height:var(--size_2);
  margin-right:2vh;
}

.item_tab:not(:last-child){
  border-right:1px solid var(--border);
}

.active_tab .inner_tab{
  border-bottom:1px solid var(--color);
}

.active_tab span{
  color:var(--color);
}

.active_tab path, .active_tab g{
  fill:var(--color);
  opacity:1;
}

.sticky{
  box-shadow:var(--shadow);
  flex-direction: row;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  top: 7vh;
  z-index:3;
  flex-shrink:0;
}

.inner_tab{
  flex:1;
  align-self:stretch;
  align-items: center;
  justify-content: center;
  padding: 3vh 2vh;
  border-bottom:1px solid transparent;
}


@media only screen and (max-width: 600px) {
  .container_tab{
    top:-1vh;
    margin:0 !important;
  }
}