.header_user{
  margin-top:9vh !important;
  align-items:center;
  justify-content: center;
  padding:2vh 1vw;
  width:100%;
}

.outer_image{
  position:relative;
}

.container_image{
  margin: 0 0.5vw;
  position:relative;
  height:8vh;
  width:8vh;
  border-radius:50%;
  overflow:hidden;
}

.input_file{
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  opacity:0;
  z-index:2;
}

.details_profile_user{
  flex-direction:column;
  flex:2;
  margin: 0 0.5vw;
  align-self: stretch;
  justify-content: space-between;
}

.user{
  flex:3;
}

.item_header{
  flex:1;
  align-items: center;
}

.name{
  font-size:var(--size_3);
}

.senior{
  background: var(--color);
  align-self:flex-start;
  border-radius:2vh;
  padding:0vh 0.5vw;
  font-size:1.5vh;
  color:white;
  margin-top:0.2vh;
}

.infos{
  color:var(--font);
  font-size:var(--size_3);
}

.intro_switch{
  font-size:var(--size_3);
  margin-right:1vw;
  color:var(--font);
}

.container_buttons{
  justify-content: space-evenly;
}

.header_user .container_field{
  margin:0
}




.item_profile_user{
  flex:1;
  align-items:center;
}

.item_profile_user img{
  height:2vh;
  width:2vh;
  margin-right:1vw
}

.label_item{
  font-size:var(--size_3);
}

.empty_user{
  background-color: var(--light_grey);
  flex:1;
  align-self: stretch;
  align-items:center;
  justify-content: center;
}

.empty_user img{
  height:3vh;
  width:3vh;
}


.container_edit{
  background: var(--color);
  height:2vh;
  width:2vh;
  padding:0.4vh;
  border-radius:50%;
  margin-left:1vw;
  overflow:hidden;
  box-sizing:border-box;
}

.item_bloc_header{
  background: var(--light_grey);
  flex:1;
  align-self:stretch;
  flex-direction:column;
  padding:1vh 1vw;
  margin:0 0.5vw;
  justify-content: space-evenly;
}

.intro_bloc{
  color:var(--font);
  font-size:var(--size_3);
  margin-bottom:1vh;
}

.content_bloc{
  flex:1;
  align-items:center;
}

.bloc_figure{
  color:var(--color);
  font-size:var(--size_2)
}

.bloc_label{
  font-size:var(--size_3);
  color:var(--font);
}

@media only screen and (max-width: 600px) {
  .header_user{
    margin:0 !important;
    box-shadow:none !important;
    flex-direction: column;
    padding:0;
  }

  .senior{
    padding:0.25vh 2vw;
  }

  .user{
    border-bottom:1px solid var(--border);
    border-top:1px solid var(--border);
    align-self: stretch;
    padding:2vh 5vw;
  }

  .container_image{
    margin-right:5vw;
  }

  .item_bloc_header{
    flex-direction: row;
    margin:0;
    padding:4vh 4vw;
    background:none;
    border-bottom:1px solid var(--border);
  }

  .content_bloc{
    flex:none;
  }

  .intro_bloc{
    flex:1;
    margin:0 !important;
    align-items:center;
  }
}
