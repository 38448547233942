.container{
  flex:1;
  flex-direction:column;
}



.container_header{
  align-items:center;
  padding:2vh 1vw;
  justify-content: space-between;
}

.title_header{
  font-size:var(--size_2);
  color:var(--font);
}

.container_type{
  border:1px solid var(--border);
  border-radius:1vh;
}

.item_type{
  padding:2vh 1vw;
  font-size:var(--size_3);
  min-width:3vw;
  align-items:center;
  justify-content: center;
  color:var(--font);
}

.active{
  color:var(--color);
}

.item_type:nth-child(2){
  border-left:1px solid var(--border) !important;
  border-right:1px solid var(--border) !important;
}




.container_graph{
  flex:1;
  position:relative;
  padding:0 3vw;
}

.container_grid{
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:10vh;
  flex-direction: column;
}

.container_grid div{
  flex:1;
  border-bottom:1px solid var(--border)
}



.container_data{
  z-index:2;
  flex:1;
  flex-direction:row-reverse;
}

.item_data{
  flex:1;
  align-items:center;
  flex-direction: column;
}

.graphs_data{
  flex:1;
  align-self: stretch;
  margin:0 2vw;
}

.legend_data{
  height:10vh;
  align-items:center;
  justify-content: center;
  color:var(--font);
  font-size:var(--size_3);
}


.data{
  flex-direction: column;
  justify-content: flex-end;
  flex:1;
  align-self:stretch;
}

.label_data{
  font-size:var(--size_3);
  align-self:center;
  margin-bottom:0.5vh;
}

.bar{
  background: var(--color);
  align-self:stretch;
  margin:0 0.2vw;
}



.container_arrow{
  position:absolute;
  bottom:5vh;
  transform:translateY(50%);
  left:2vw;
  z-index:3;
}

.container_arrow_back{
  transform:translateY(50%) rotate(180deg);
}

.container_arrow_forward{
  right:2vw;
  left:auto;
}