


.banner_buttons{
	align-items:center;
	align-self: center;
}

.button{
	height:3vh;
	width:3vh;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	overflow:hidden;
	background-color: var(--grey);
	margin:0 0.5vw;
}

.button_eval_cab{
	background-color:var(--grey);
	opacity:0.6;
}

.button_validate{
	background-color: var(--green);
}

.button_delete{
	background-color: var(--red);
}

.button_reboot{
  background-color: var(--orange);
}

.container{
	position:relative;
}

.overlay{
	display:none;
	position:absolute;
	width:10vw;
	transform:translateX(-50%);
	padding:1vh 1vw;
	color:var(--font);
	font-size:var(--size_3);
	top:2vh;
}

.container:hover .overlay{
	display:flex;
}